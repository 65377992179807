import { motion } from 'framer-motion';
import React from 'react';
interface CloseIconProps {
  handleClick: () => void;
}
const CloseIcon = ({ handleClick }: CloseIconProps) => {
  return (
    <motion.i
      className="pi pi-times cursor-pointer rounded-lg bg-transparent p-2 text-gray-400 transition-colors hover:bg-gray-100 hover:text-neutral-600"
      whileTap={{
        scale: 0.8,
      }}
      onClick={() => handleClick()}
    />
  );
};

export default CloseIcon;
