import { motion } from 'framer-motion';
import Lottie from 'lottie-react';
import Button from '../../../shared/components/Button';
import {
  fadeIn,
  slideUpFromBottomDelayed,
} from '../../../shared/helpers/animation';
import bluetoothConnected from '../../../shared/helpers/animation/bluetoothConnected.json';
import { LoadingState } from '../../../shared/types';

const NewClientStep2 = ({
  setActiveIndex,
  handleConnectWatch,
  watchLoading,
  btDevice,
}: {
  setActiveIndex: (index: number) => void;
  handleConnectWatch: () => void;
  watchLoading: boolean;
  btDevice: any;
}) => {
  const handleContinue = async () => {
    setActiveIndex(2);
  };
  return (
    <motion.main
      className="flex h-[410px] flex-col items-center justify-between"
      variants={fadeIn}
      initial="hidden"
      animate="visible"
    >
      <motion.p className="mt-12 max-w-md text-center text-lg font-medium">
        Pair the Caloosha Watch to your new client by connecting it via
        bluetooth
      </motion.p>
      <div className="flex items-center justify-center">
        {!btDevice ? (
          <Button
            label="Connect watch"
            icon="pi pi-wifi"
            loading={watchLoading}
            onClick={() => handleConnectWatch()}
            initial={{
              opacity: 1,
            }}
            animate={{
              opacity: 1,
            }}
            exit={{
              opacity: 0,
            }}
            className="my-22"
          />
        ) : (
          <div className="flex flex-col items-center">
            <Lottie
              animationData={bluetoothConnected}
              loop={false}
              className="w-12"
            />
            <motion.p
              className=" mt-2 text-lg font-bold"
              variants={slideUpFromBottomDelayed}
              initial="hidden"
              animate="visible"
            >
              Watch connected
            </motion.p>
          </div>
        )}
      </div>
      <div className="mt-20 flex w-full gap-x-4">
        <Button
          className="p-button-outlined p-button-info w-max"
          onClick={() => setActiveIndex(0)}
          label="Back"
        />
        <Button
          className="p-button-info grow"
          disabled={!btDevice}
          onClick={() => handleContinue()}
          label="Continue"
        />
      </div>
    </motion.main>
  );
};

export default NewClientStep2;
