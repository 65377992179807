import { useEffect, useState } from 'react';
import { Accordion, AccordionTab } from 'primereact/accordion';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { motion } from 'framer-motion';
import { slideDownFromTop } from '../../../shared/helpers/animation';
import './FrequentlyAskedQuestions.css';
import useIsAdmin from '../../../shared/hooks/useIsAdmin';
import { PlusCircleIcon } from '@heroicons/react/solid';
import { Dialog } from 'primereact/dialog';
import CmsQuestionForm from './CmsQuestionForm';
import { Button } from 'primereact/button';
import CloseIcon from '../../components/CloseIcon';
import useFaqService from '../../../shared/services/useFaqService';
import { useAppSelector } from '../../../shared/store/store';
import FaqRow from './FaqRow';
import { FrequentlyAskedQuestion } from '../../../shared/types';

const FrequentlyAskedQuestions = () => {
  //State
  const [showModal, setShowModal] = useState(false);
  const [newQuestion, setNewQuestion] = useState('');
  const [newAnswer, setNewAnswer] = useState('');

  //Hooks
  const { isAdmin } = useIsAdmin();
  const { createFaq, getAllFaqs } = useFaqService();
  const { allFaqs, createFaqLoading, updateFaqLoading, deleteFaqLoading } =
    useAppSelector((state) => state.faq);

  //Methods
  const handleOpenModal = () => {
    setShowModal(true);
  };

  const handleCreateQuestion = () => {
    try {
      createFaq({
        question: newQuestion,
        answer: newAnswer,
      });
      setShowModal(false);
      setNewQuestion('');
      setNewAnswer('');
    } catch (error) {
      console.error(error);
    }
  };

  const sortById = (faqs: FrequentlyAskedQuestion[]) => {
    //Must duplicate array to sort it and return
    const arrayToSort = [...faqs];
    if (faqs.length > 1) {
      return arrayToSort.sort((a, b) => {
        return a.id! + 1 - (b.id! + 1);
      });
    } else return faqs;
  };

  //Effects
  useEffect(() => {
    getAllFaqs();
  }, [createFaqLoading, updateFaqLoading, deleteFaqLoading]);

  // Heart Rate information provided by client to populate uneditable question
  const [heartRates, setHeartRates] = useState([
    { age: '1-2', bpm: '120', normalRange: '100-150' },
    { age: '2-3', bpm: '110', normalRange: '95-140' },
    { age: '3-5', bpm: '100', normalRange: '85-120' },
    { age: '5-6', bpm: '95', normalRange: '80-120' },
    { age: '6-10', bpm: '90', normalRange: '75-110' },
    { age: '10-12', bpm: '81', normalRange: '65-100' },
    { age: '12-13', bpm: '79', normalRange: '60-100' },
    { age: '13-18', bpm: '75', normalRange: '60-100' },
  ]);

  return (
    <div className="flex w-full flex-col gap-y-4 p-8">
      <header className="mb-4 flex items-center gap-x-6">
        <motion.h1
          className="text-3xl font-bold"
          variants={slideDownFromTop}
          initial="hidden"
          animate="visible"
        >
          Frequently Asked Questions
        </motion.h1>
      </header>
      <motion.section>
        {allFaqs &&
          allFaqs.length > 0 &&
          sortById(allFaqs).map(({ id, question, answer }, index) => (
            <FaqRow
              id={id}
              question={question}
              answer={answer}
              index={index}
              key={index}
            />
          ))}
        {/* This question needs more than simple Rich Text to edit, so it won't be editable */}
        <Accordion>
          <AccordionTab header="What are average heart rates for children?">
            <DataTable value={heartRates} responsiveLayout="scroll">
              <Column field="age" header="Age (in years)"></Column>
              <Column field="bpm" header="Average BPM"></Column>
              <Column field="normalRange" header="Normal BPM Range"></Column>
            </DataTable>
            <p className="mt-8 text-sm italic">
              1 From: Fleming, S., Thompson, M., Stevens, R., Heneghan, C.,
              Plüddemann, A., Maconochie, I., Tarassenko, L., & Mant, D. 2011.
              Normal ranges of heart rate and respiratory rate in children from
              birth to 18 years of age: a systematic review of observational
              studies. Lancet London, England, 377 9770, 1011–1018.
            </p>
            <p className="my-6 font-medium">
              During anxiety attacks, it is not uncommon for heart rates to
              increase by approximately 2X.
            </p>
          </AccordionTab>
        </Accordion>
      </motion.section>

      {isAdmin && (
        <motion.button
          type="button"
          whileTap={{ scale: 0.95 }}
          className="relative flex w-full items-center justify-center gap-x-2 rounded-lg border-2 border-dashed border-gray-300 p-4 text-center font-medium text-gray-700 hover:border-gray-400 focus:border-primary-light focus:outline-none"
          onClick={() => handleOpenModal()}
        >
          <PlusCircleIcon className="h-6 w-6" />
          <span className="">Add a new question</span>
        </motion.button>
      )}
      <Dialog
        visible={showModal}
        onHide={() => setShowModal(false)}
        style={{ width: '800px' }}
        showHeader={false}
        className="rounded-lg"
      >
        <div className="flex flex-col gap-y-4 px-4 py-8">
          <div className=" flex w-full justify-between">
            <h2 className="text-2xl font-bold">Add a new question</h2>
            <CloseIcon handleClick={() => setShowModal(false)} />
          </div>
          <CmsQuestionForm
            question={newQuestion}
            handleQuestionChange={setNewQuestion}
            answer={newAnswer}
            handleAnswerChange={setNewAnswer}
          />
          <Button
            className="justify-center"
            onClick={() => handleCreateQuestion()}
          >
            Save
          </Button>
        </div>
      </Dialog>
    </div>
  );
};

export default FrequentlyAskedQuestions;
