import { Client, Watch, Profile, WatchData, WatchSettings } from './schema';

export * from './schema';
export * from './shared-types';

// union type for client with watch
export type ClientWithWatch = Client & {
  watch: Watch;
};

export type WatchDataWithSettings = WatchData & {
  settings: WatchSettings;
};

export interface SettingsData {
  restingHr: number;
  timeoutAfterIgnore: number;
  timeoutAfterNo: number;
  timeoutAfterSuccess: number;
  triggerHr: number;
  numberOfCycles: number;
}

export interface NewClient {
  firstName: string;
  lastName: string;
  serviceTypeId: string;
  dateOfBirth: Date;
  externalClientId: string | null;
}

export type NewClientWithTherapist = NewClient & {
  therapistId: string;
};

/**
 * Combining loading state and error in order to clean up reducer and make
 * displaying loading and success/error modals easier in the app.
 * Motivated by this article: https://medium.com/angular-in-depth/ngrx-how-and-where-to-handle-loading-and-error-states-of-ajax-calls-6613a14f902d
 */
export const enum LoadingState {
  INIT = 'INIT',
  LOADED = 'LOADED',
  LOADING = 'LOADING',
}

export interface ErrorState {
  error: any;
}

export type CallState = LoadingState | ErrorState;
