import { createSlice } from '@reduxjs/toolkit';
import {
  CallState,
  HeartRateDefault,
  LoadingState,
  ServiceType,
} from '../types';

export interface StoredConstantsState {
  serviceTypes: ServiceType[]; // TODO: - change to correct type
  serviceTypesLoading: CallState;
  heartRateDefaults: HeartRateDefault[];
  heartRateDefaultsLoading: CallState;
}

export const storedConstantsSlice = createSlice({
  name: 'storedConstants',
  initialState: {
    serviceTypes: [],
    serviceTypesLoading: LoadingState.INIT,
    heartRateDefaults: [],
    heartRateDefaultsLoading: LoadingState.INIT,
  } as StoredConstantsState,
  reducers: {
    getServiceTypes: (state) => {
      state.serviceTypesLoading = LoadingState.LOADING;
    },
    getServiceTypesSuccess: (state, { payload }) => {
      state.serviceTypesLoading = LoadingState.LOADED;
      state.serviceTypes = payload;
    },
    getServiceTypesFailure: (state, { payload }) => {
      state.serviceTypesLoading = { error: payload };
    },
    getHeartRateDefaults: (state) => {
      state.heartRateDefaultsLoading = LoadingState.LOADING;
    },
    getHeartRateDefaultsSuccess: (state, { payload }) => {
      state.heartRateDefaultsLoading = LoadingState.LOADED;
      state.heartRateDefaults = payload;
    },
    getHeartRateDefaultsFailure: (state, { payload }) => {
      state.heartRateDefaultsLoading = { error: payload };
    },
  },
});

export const {
  getServiceTypes,
  getServiceTypesSuccess,
  getServiceTypesFailure,
  getHeartRateDefaults,
  getHeartRateDefaultsSuccess,
  getHeartRateDefaultsFailure,
} = storedConstantsSlice.actions;

export default storedConstantsSlice.reducer;
