import { useEffect, useState } from 'react';
import { useAppSelector } from '../store/store';
const useIsAdmin = () => {
  const { userProfile } = useAppSelector((state) => state.profile);
  const [isAdmin, setIsAdmin] = useState(false);

  useEffect(() => {
    if (userProfile) {
      setIsAdmin(userProfile?.role.includes('admin'));
    }
  }, [userProfile]);
  return { isAdmin };
};

export default useIsAdmin;
