import { fromUnixTime } from 'date-fns';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { showToast } from '../store/alerts';
import { useAppSelector } from '../store/store';
import { SettingsData } from '../types';
import { BluetoothConnection } from './bluetooth-connection';
import { CSV_FILENAME, JS_FILENAME } from './constants';

const useBluetooth = () => {
  const bluetooth = new BluetoothConnection();
  const [bluetoothReady, setBluetoothReady] = useState(false);
  const [device, setDevice] = useState<any>();
  const { client } = useAppSelector((state) => state.client);
  const dispatch = useDispatch();

  const connectBluetooth = async (): Promise<{
    ready: boolean;
    device: any;
  }> => {
    return new Promise((resolve, reject) => {
      bluetooth
        .connect((btReady, device) => {
          setDevice(device);
          if (checkClientDevice()) {
            setBluetoothReady(btReady);
            resolve({ ready: btReady, device });
          } else {
            reject('Client device not found');
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  useEffect(() => {
    setBluetoothReady(bluetooth.btReady);
  }, [bluetooth.btReady]);

  const checkClientDevice = () => {
    if (
      bluetooth.btReady &&
      client &&
      client.watch?.deviceName !== bluetooth.btDevice?.name
    ) {
      bluetooth.closeConnection();
      setBluetoothReady(false);
      dispatch(
        showToast({
          severity: 'error',
          summary: 'Wrong device connected',
          detail: 'Please connect the device associated with the client',
        }),
      );
      return false;
    }
    return true;
  };

  const downloadCalooshaCSV = async () => {
    console.log('downloading file', CSV_FILENAME);
    let text = await bluetooth.downloadFile(CSV_FILENAME);
    text = text.replace('realBPM: ', '');
    const split = text.split('\n');
    const replaced = split.map((s: string) => {
      return s
        .replace('realBPM: ', '')
        .replace('calculatedBPM: ', '')
        .replace('timestamp: ', '')
        .replace('event: ', '');
    });
    console.log('split', replaced);
    replaced.join('\n');
    // @ts-ignore - replaced is correct type
    const blob = new Blob([replaced], { type: 'text/plain' });
    const url = URL.createObjectURL(blob);
    // download the file
    const a = document.createElement('a');
    a.href = url;
    a.download = `caloosha_event_log.csv`;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);
    console.log('text updated', text);
  };

  const downloadCalooshaData = async () => {
    try {
      await connectBluetooth();
      const text = await bluetooth.downloadFile(CSV_FILENAME);
      const split = text.split('\n');
      const rawData = [];
      const watchData = [];
      for (let i = 0; i < split.length; i++) {
        const s = split[i];
        const replaced = s
          .replace('realBPM: ', '')
          .replace('calculatedBPM: ', '')
          .replace('timestamp: ', '')
          .replace('event: ', '');
        const values = replaced.split(',');
        const event = values[3];
        // only add to parsed if event is valid
        if (event && !event.toLowerCase().includes('invalid')) {
          rawData.push({
            realBPM: parseInt(values[0]),
            calculatedBPM: parseInt(values[1]),
            timestamp: parseInt(values[2]),
            event: values[3],
          });
        }
      }
      // iterate over raw data to figure out the actual events
      for (let i = 0; i < rawData.length; i++) {
        const { realBPM, calculatedBPM, timestamp, event } = rawData[i];
        if (event.toLowerCase().includes('elevated')) {
          const data: {
            eventDate: Date;
            triggerHR: number;
            actionTaken: 'yes' | 'no' | 'ignore';
            postActionHR: number | null;
            actionRepeated: 'yes' | 'no' | 'ignore' | null;
            finalHR: number | null;
          } = {
            triggerHR: calculatedBPM,
            eventDate: fromUnixTime(timestamp),
            actionTaken: 'no',
            postActionHR: null,
            actionRepeated: null,
            finalHR: null,
          };
          // while loop to generate event object.
          for (let j = i + 1; j < rawData.length; j++) {
            let nextItem = rawData[j];
            const nextEvent = nextItem.event.toLowerCase();
            if (nextEvent.includes('elevated')) {
              break;
            }
            // initial action has been taken
            if (data.actionTaken === 'yes') {
              if (nextEvent.includes('accepted')) {
                data.actionRepeated = 'yes';
              }
              if (nextEvent.includes('complete')) {
                if (data.actionRepeated === 'yes') {
                  data.finalHR =
                    nextItem.calculatedBPM === 0
                      ? nextItem.realBPM
                      : nextItem.calculatedBPM;
                } else {
                  data.postActionHR =
                    nextItem.calculatedBPM === 0
                      ? nextItem.realBPM
                      : nextItem.calculatedBPM;
                }
              }
              if (nextEvent.includes('ignore')) {
                data.actionRepeated = 'ignore';
              }
              if (nextEvent.includes('declined')) {
                data.actionRepeated = 'no';
              }
            } else {
              if (nextEvent.includes('accepted')) {
                data.actionTaken = 'yes';
              }
              if (nextEvent.includes('ignored')) {
                data.actionTaken = 'ignore';
              }
              if (nextEvent.includes('declined')) {
                data.actionTaken = 'no';
              }
            }
          }
          watchData.push(data);
        }
      }

      return {
        rawData,
        watchData,
      };
    } catch (error) {
      throw error;
    }
  };

  const eraseColooshaCSV = () => {
    return bluetooth.deleteFile(CSV_FILENAME);
  };

  const eraseSettings = () => {
    return bluetooth.deleteFile(JS_FILENAME);
  };

  const saveWatchSettings = async (settings: SettingsData) => {
    await connectBluetooth();
    const file = await fetch(`${process.env.REACT_APP_URL}/caloosha.app.js`);
    const blob = await file.blob();
    const blobText = await blob.text();
    const firstSplit = blobText.split('// START SETTINGS');
    const secondSplit = firstSplit[1].split('// END SETTINGS');
    const updatedSettings = `
    // START SETTINGS:

    //elevated BPM number
    const BPM_ALERT_THRESHOLD = ${settings.triggerHr};
    //true or false - lowecase
    const LOG_DATA = true;
    //set allowed movement between 0 and 1
    const MOVEMENT_THRESHOLD = 0.15;
    //0: inhuman stillness; 1:allow high movement;
    
    //Times in millisecond
    const TIMEOUT_AFTER_IGNORED = 1000 * ${settings.timeoutAfterIgnore};
    const TIMEOUT_AFTER_NO = 1000 * ${settings.timeoutAfterNo};
    const TIMEOUT_AFTER_SUCCESS = 1000 * ${settings.timeoutAfterSuccess};
    const TIME_TO_RESPOND = 5000;
    const TIME_ELEVATED_BPM_THRESHOLD = 10000;
    const TIME_TO_SHOW_SUCCESS = 5000;
    const NUMBER_OF_CYCLES = ${settings.numberOfCycles};
    const AVERAGE_HEART_RATE = ${settings.restingHr};
    //shows alert if HR elevated for 10 seconds.
    
    //If HR is below BPM_ALERT_THRESHOLD by this %, show full success
    //Set value between zero and 1
    const SUCCESS_PERCENT = 0.2;
    // END SETTINGS
    `;
    const joined = firstSplit[0] + updatedSettings + secondSplit[1];
    // upload file - currently not working with uploadCode right after
    // const uploadFile = await bluetooth.uploadFile(JS_FILENAME, joined);
    // setup code on device
    const success = await bluetooth.uploadCode(joined);
    bluetooth.closeConnection();
    return success;
  };

  return {
    bluetoothReady,
    connectBluetooth,
    downloadCalooshaCSV,
    downloadCalooshaData,
    eraseColooshaCSV,
    saveWatchSettings,
    eraseSettings,
    device,
  };
};
export default useBluetooth;
