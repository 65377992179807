export const NORDIC_DEFAULT_TX_LENGTH = 20; ///< default value for maxPacketLength
export const NORDIC_SERVICE = '6e400001-b5a3-f393-e0a9-e50e24dcca9e';
export const NORDIC_TX = '6e400002-b5a3-f393-e0a9-e50e24dcca9e';
export const NORDIC_RX = '6e400003-b5a3-f393-e0a9-e50e24dcca9e';
export const DEFAULT_CHUNKSIZE = 20;
export const DEVICES = [
  'Puck.js',
  'Pixl.js',
  'MDBT42Q',
  'Espruino',
  'Badge',
  'Thingy',
  'RuuviTag',
  'iTracker',
  'Smartibot',
  'Bangle.js',
  'Micro:bit',
];
export const CSV_FILENAME = 'caloosha_event_log.csv\u0001'; // this is a possible weak point.  if filename ever changes, this will break
export const JS_FILENAME = 'caloosha.app.js'; // this is a possible weak point.  if filename ever changes, this will break
