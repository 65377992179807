import { motion } from 'framer-motion';
import { Editor } from 'primereact/editor';
import { InputText } from 'primereact/inputtext';
import React from 'react';
interface QuestionFormProps {
  question?: string;
  handleQuestionChange: (e: any) => void;
  answer?: string;
  handleAnswerChange: (e: any) => void;
}
const CmsQuestionForm = ({
  question = '',
  answer = '',
  handleQuestionChange,
  handleAnswerChange,
}: QuestionFormProps) => {
  return (
    <motion.div className="mt-2 flex flex-col" layout>
      <label className="ml-2 mb-1 text-sm font-medium" htmlFor="questionInput">
        Question
      </label>
      <InputText
        id="questionInput"
        value={question}
        onChange={(e) => handleQuestionChange(e.target.value)}
      />
      <label className="mt-4 ml-2 mb-1 text-sm font-medium" htmlFor="editor">
        Answer
      </label>
      <Editor
        id="editor"
        style={{ height: '320px' }}
        value={answer}
        onTextChange={(e) => handleAnswerChange(e.htmlValue as string)}
      />
    </motion.div>
  );
};

export default CmsQuestionForm;
