import { motion } from 'framer-motion';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { useState, useEffect, useRef } from 'react';
import { slideDownFromTop } from '../../../shared/helpers/animation';
import { useAppSelector } from '../../../shared/store/store';
import useProfileService from '../../../shared/services/useProfileService';
import { FilterMatchMode } from 'primereact/api';
import { InputText } from 'primereact/inputtext';
import Button from '../../../shared/components/Button';
import useCSVService from '../../../shared/services/useCSVService';
import { Profile } from '../../../shared/types';

const AdminDashboard = () => {
  //State
  const { userProfile, allProfiles } = useAppSelector((state) => state.profile);
  const [activeUser, setActiveUser] = useState<any>(null);
  const [filters, setFilters] = useState<any>(null);
  const [searchText, setSearchText] = useState('');
  const [expandedRows, setExpandedRows] = useState<any>(null);
  const { downloadUserWatchDataCSV, downloadAllWatchDataCSV } = useCSVService();
  const [downloading, setDownloading] = useState(false);
  //Hooks
  const { getAllProfiles } = useProfileService();

  //useEffects
  useEffect(() => {
    getAllProfiles();
  }, []);
  useEffect(() => {
    initFilters();
  }, []);

  //Functions
  const onRowCollapse = (event: any) => {
    setActiveUser(null);
  };

  const rowExpansionTemplate = (data: Profile) => {
    return (
      <div className="ml-20 flex items-center gap-x-24">
        <div className="flex flex-col gap-y-4">
          <h2>
            <span className="font-bold">Organization name: </span>
            {data.organization}
          </h2>
          <h2>
            <>
              <span className="font-bold">Date joined: </span>
              {data.createdOn}
            </>
          </h2>
        </div>
        <Button
          label="Download Data"
          onClick={() => handleDownloadProfile(data)}
          loading={downloading}
        />
      </div>
    );
  };

  //Functions
  const onGlobalFilterChange = (e: any) => {
    const value = e.target.value;
    let _filters = { ...filters };
    _filters['global'].value = value;

    setFilters(_filters);
    setSearchText(value);
  };

  const initFilters = () => {
    setFilters({
      global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    });
    setSearchText('');
  };

  const clearSearch = () => {
    initFilters();
  };

  const handleDownloadAll = async () => {
    try {
      setDownloading(true);
      await downloadAllWatchDataCSV();
    } finally {
      setDownloading(false);
    }
  };

  const handleDownloadProfile = async (profile: Profile) => {
    try {
      setDownloading(true);
      await downloadUserWatchDataCSV(profile);
    } finally {
      setDownloading(false);
    }
  };

  return (
    <div>
      <header className="flex w-full justify-between p-8">
        <div>
          <motion.h1
            className="text-3xl font-bold"
            variants={slideDownFromTop}
            initial="hidden"
            animate="visible"
          >
            Admin Dashboard
          </motion.h1>
          <p className=" font-light text-neutral-500">
            {userProfile?.firstName} {userProfile?.lastName}
          </p>
        </div>
      </header>
      <div className="card ml-8 w-4/5">
        <div className="flex w-full items-center justify-between">
          <span
            className={`p-input-icon-left mb-6 ${
              searchText !== '' && 'p-input-icon-right'
            }`}
          >
            <i className="pi pi-search" />
            <InputText
              value={searchText}
              onChange={onGlobalFilterChange}
              placeholder="Search"
            />
            {searchText !== '' && (
              <motion.i
                className="pi pi-times cursor-pointer"
                onClick={() => clearSearch()}
              />
            )}
          </span>
          <Button
            label="Download all data"
            onClick={() => handleDownloadAll()}
            loading={downloading}
          />
        </div>
        <DataTable
          value={allProfiles}
          expandedRows={expandedRows}
          responsiveLayout="scroll"
          stripedRows
          globalFilterFields={[
            'firstName',
            'lastName',
            'email',
            'organization',
          ]}
          filters={filters}
          onRowCollapse={onRowCollapse}
          dataKey="id"
          onRowToggle={(e) => setExpandedRows(e.data)}
          rowExpansionTemplate={rowExpansionTemplate}
          sortField="lastLogin"
          sortOrder={1}
        >
          <Column expander style={{ width: '1em' }} />
          <Column field="firstName" header="First" sortable></Column>
          <Column field="lastName" header="Last" sortable></Column>
          <Column field="email" header="Email"></Column>
          <Column field="lastLogin" header="Last login" sortable></Column>
        </DataTable>
      </div>
    </div>
  );
};

export default AdminDashboard;
