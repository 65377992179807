import { Toast } from 'primereact/toast';
import { useEffect, useRef } from 'react';
import { removeToast } from '../../store/alerts';
import { useAppDispatch, useAppSelector } from '../../store/store';

const AppToast = () => {
  const { toasts } = useAppSelector((state) => state.alerts);
  const dispatch = useAppDispatch();
  const toastRef = useRef<any>(null);

  useEffect(() => {
    if (toasts.length > 0 && toastRef.current) {
      const toast = toasts[0];
      toastRef.current.show({
        severity: toast.severity,
        summary: toast.summary,
        detail: toast.detail,
        life: toast.duration,
      });
      setTimeout(
        () => {
          // remove from toasts after shown
          toastRef.current.clear();
          dispatch(removeToast());
        },
        // stack multiple toasts
        toasts.length > 1
          ? (toast.duration || 3000) / 2
          : toast.duration || 3000,
      );
    }
  }, [JSON.stringify(toasts)]);

  return <Toast ref={toastRef} />;
};

export default AppToast;
