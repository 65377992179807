import { useEffect, useState } from 'react';
import SidebarListItem from '../SidebarListItem';
import { motion, useAnimation } from 'framer-motion';
import LogoNoText from '../../../../shared/components/LogoNoText';
import { fadeInShortDelay } from '../../../../shared/helpers/animation';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { sidebarAnimation } from '../../../../shared/helpers/animation';
import { useAppSelector } from '../../../../shared/store/store';
import { useReducedMotion } from 'framer-motion';

export interface SidebarLink {
  to: string;
  label: string;
  icon: string;
}

const Sidebar: React.FC<{ links: SidebarLink[] }> = ({ links }) => {
  //State
  const [expanded, setExpanded] = useState(false);
  const [stayExpanded, setStayExpanded] = useState(false);
  const [conditionalLinks, setConditionalLinks] = useState<SidebarLink[]>([]);

  const { client } = useAppSelector((state) => state.client);

  //Hooks
  const controls = useAnimation();
  const navigate = useNavigate();
  const location = useLocation();
  const { id } = useParams();
  const reducedMotion = useReducedMotion();

  //Effects
  useEffect(() => {
    if (id) {
      const temp = links.filter(
        (link) => link.to.includes(id) || link.to.includes('/client-list'),
      );
      setConditionalLinks(temp);
    } else {
      const temp = links.filter((link) => !link.to.includes('/client/'));
      setConditionalLinks(temp);
    }
  }, [client, location]);

  //Keeps sidebar open when reduced motion is enabled on device
  useEffect(() => {
    if (reducedMotion) {
      setExpanded(true);
      setStayExpanded(true);
    } else {
      setExpanded(false);
      setStayExpanded(false);
    }
  }, [reducedMotion]);

  //Methods
  const handleStayExpanded = () => {
    setStayExpanded(!stayExpanded);
  };

  const enter = () => {
    if (!stayExpanded) {
      controls.start('visible');
      setExpanded(true);
    }
  };

  const leave = () => {
    if (!stayExpanded) {
      controls.start('hidden');
      setExpanded(false);
    }
  };

  return (
    <motion.div
      className={`h-full w-max bg-primary-light`}
      variants={sidebarAnimation}
      animate={controls}
      initial={reducedMotion ? 'visible' : 'hidden'}
      onMouseEnter={() => enter()}
      onMouseLeave={() => leave()}
    >
      <nav
        className={`flex h-full w-full flex-col justify-between py-4 text-white`}
      >
        <div>
          <motion.div
            style={{ width: '2em' }}
            className="mr-2 mb-8 ml-5 cursor-pointer"
            onClick={() => navigate('/dashboard/client-list')}
          >
            <LogoNoText />
          </motion.div>
          <motion.ul
            variants={sidebarAnimation}
            animate={controls}
            initial={reducedMotion ? 'visible' : 'hidden'}
            className="flex flex-col"
          >
            {conditionalLinks.map((link) => (
              <SidebarListItem link={link} key={link.to} expanded={expanded} />
            ))}
          </motion.ul>
        </div>
        {expanded && (
          <motion.div
            className="mx-auto mb-4 flex w-max cursor-pointer items-center justify-start gap-x-2 rounded-md py-2 px-4 text-[#8EDCE5]"
            onClick={() => handleStayExpanded()}
            whileTap={{ scale: 0.9 }}
            variants={fadeInShortDelay}
            initial="hidden"
            animate="visible"
          >
            <motion.i
              className={`pi font-bold transition-all ${
                stayExpanded ? 'pi-angle-double-left' : 'pi-angle-double-right'
              }`}
            />
            <p className="font-branded text-sm font-bold">
              {stayExpanded ? 'Close sidebar' : 'Keep sidebar open'}
            </p>
          </motion.div>
        )}
      </nav>
    </motion.div>
  );
};

export default Sidebar;
