import React from 'react';
import AnimatedLogoText from '../../../shared/components/AnimatedLogoText';
import { motion } from 'framer-motion';
import { slideUpFromBottomDelayed } from '../../../shared/helpers/animation';
import PageTransitionWrapper from '../../../shared/components/PageTransitionWrapper';
import { useAuth0 } from '@auth0/auth0-react';
import Button from '../../../shared/components/Button';

const GetStarted: React.FC = () => {
  const { loginWithRedirect } = useAuth0();
  const handleLogin = () => {
    loginWithRedirect();
  };
  return (
    <PageTransitionWrapper>
      <main className="flex h-screen w-full items-center justify-center bg-gradient-to-bl from-cyan-400 to-cyan-900">
        <motion.div className="flex w-3/4 flex-col items-center p-6 md:w-2/4 lg:w-[500px]">
          <motion.div
            animate={{ scale: 1.05 }}
            transition={{
              repeat: Infinity,
              duration: 2,
              delay: 3,
              repeatType: 'reverse',
            }}
            className="mb-20 w-full"
          >
            <AnimatedLogoText color="white" />
          </motion.div>
          <Button
            label="Get started"
            className="p-button-info w-full lg:w-3/5"
            onClick={() => {
              handleLogin();
            }}
            variants={slideUpFromBottomDelayed}
            initial="hidden"
            animate="visible"
          />
        </motion.div>
      </main>
    </PageTransitionWrapper>
  );
};

export default GetStarted;
