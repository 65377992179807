import { useEffect, useState } from 'react';
import { Accordion, AccordionTab } from 'primereact/accordion';
import CmsEditButton from '../CmsEditButton';
import CmsQuestionForm from '../CmsQuestionForm';
import useIsAdmin from '../../../../shared/hooks/useIsAdmin';
import {
  FrequentlyAskedQuestion,
  LoadingState,
} from '../../../../shared/types';
import { motion } from 'framer-motion';
import useFaqService from '../../../../shared/services/useFaqService';
import { fadeIn } from '../../../../shared/helpers/animation';
import CmsDeleteButton from '../CmsDeleteButton';
import { useAppSelector } from '../../../../shared/store/store';

interface FaqRowProps extends FrequentlyAskedQuestion {
  index: number;
}

const FaqRow = ({ id, question, answer, index }: FaqRowProps) => {
  //State
  const [editing, setEditing] = useState(false);
  const [questionInput, setQuestionInput] = useState(question);
  const [answerInput, setAnswerInput] = useState(answer);
  const [showEditInHeader, setShowEditInHeader] = useState(true);
  const { updateFaqLoading, deleteFaqLoading } = useAppSelector(
    (state) => state.faq,
  );
  const [loading, setLoading] = useState(false);

  //Hooks
  const { isAdmin } = useIsAdmin();
  const { updateFaq, deleteFaq } = useFaqService();

  //Methods
  const handleEditClick = () => {
    if (!loading && editing) {
      updateFaq({ id, question: questionInput, answer: answerInput });
      setEditing(false);
    } else if (!loading && !editing) {
      setEditing(true);
    }
  };

  const handleDeleteClick = () => {
    if (!loading && id) {
      deleteFaq(id);
    }
  };

  //Effects
  useEffect(() => {
    if (
      updateFaqLoading === LoadingState.LOADING ||
      deleteFaqLoading === LoadingState.LOADING
    ) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [updateFaqLoading, deleteFaqLoading]);

  return (
    <motion.div
      variants={fadeIn}
      initial="hidden"
      animate="visible"
      exit="hidden"
    >
      <Accordion
        onTabOpen={(e) => {
          setShowEditInHeader(false);
        }}
        onTabClose={() => {
          setShowEditInHeader(true);
        }}
      >
        <AccordionTab
          header={
            <div className="flex w-full items-center justify-between gap-x-2">
              <span>{question}</span>

              {isAdmin && showEditInHeader && (
                <motion.button
                  variants={fadeIn}
                  initial="hidden"
                  animate="visible"
                  exit="hidden"
                  onClick={() => {
                    handleEditClick();
                  }}
                >
                  Edit
                </motion.button>
              )}
            </div>
          }
        >
          {isAdmin && (
            <div className="flex justify-start gap-x-4">
              <CmsEditButton
                editing={editing}
                loading={updateFaqLoading === LoadingState.LOADING}
                handleClick={handleEditClick}
              />
              <CmsDeleteButton
                handleClick={handleDeleteClick}
                loading={deleteFaqLoading === LoadingState.LOADING}
              />
            </div>
          )}
          {editing ? (
            <CmsQuestionForm
              question={questionInput}
              handleQuestionChange={setQuestionInput}
              answer={answerInput}
              handleAnswerChange={setAnswerInput}
            />
          ) : (
            <div
              dangerouslySetInnerHTML={{ __html: answer }}
              className="faq-content mt-4"
            />
          )}
        </AccordionTab>
      </Accordion>
    </motion.div>
  );
};

export default FaqRow;
