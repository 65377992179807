/**
 * Model Profile
 *
 */
export type Profile = {
  id: string;
  createdOn: Date;
  updatedOn: Date;
  lastLogin: Date;
  email: string;
  role: Role;
  firstName: string;
  lastName: string;
  organization: string;
};

/**
 * Model Client
 *
 */
export type Client = {
  id: string;
  firstName: string;
  lastName: string;
  dateOfBirth: Date;
  externalClientId: string | null;
  serviceTypeId: number;
  watchId: number | null;
  therapistId: string;
  createdOn: Date;
  updatedOn: Date;
};

/**
 * Model Watch
 *
 */
export type Watch = {
  id: number;
  createdOn: Date;
  updatedOn: Date;
  clientId: string;
  deviceId: string;
  deviceName: string;
};

/**
 * Model RawWatchData
 *
 */
export type RawWatchData = {
  id: number;
  timestamp: number;
  realBPM: number;
  calculatedBPM: number;
  event: string;
  watchId: number;
};

/**
 * Model WatchData
 *
 */
export type WatchData = {
  id: number;
  watchId: number;
  createdOn: Date;
  updatedOn: Date;
  viewed: Date | null;
  eventDate: Date;
  eventNotes: string | null;
  triggerHR: number;
  actionTaken: Action | null;
  postActionHR: number | null;
  actionRepeated: Action | null;
  finalHR: number | null;
  settingsId: number | null;
};

/**
 * Model WatchSettings
 *
 */
export type WatchSettings = {
  id: number;
  createdOn: Date;
  updatedOn: Date;
  restingHr: number;
  timeoutAfterIgnore: number;
  timeoutAfterNo: number;
  timeoutAfterSuccess: number;
  triggerHr: number;
  numberOfCycles: number;
  watchId: number | null;
  clientId: string;
};

/**
 * Model ServiceType
 *
 */
export type ServiceType = {
  id: number;
  name: string;
  insuranceCode: string;
};

/**
 * Model HeartRateDefault
 *
 */
export type HeartRateDefault = {
  id: number;
  age: number;
  bpmHigh: number;
  bpmLow: number;
  bpmAverage: number;
};

/**
 * Model FrequentlyAskedQuestion
 *
 */
export type FrequentlyAskedQuestion = {
  id?: number;
  question: string;
  answer: string;
};

/**
 * Enums
 */

// Based on
// https://github.com/microsoft/TypeScript/issues/3192#issuecomment-261720275
export enum Role {
  user = 'user',
  admin = 'admin',
}

export enum Action {
  yes = 'yes',
  no = 'no',
  ignore = 'ignore',
}
