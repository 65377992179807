import { Outlet } from 'react-router-dom';
import SidebarLayout from './components/SidebarLayout';
import { SidebarLink } from './components/SidebarLayout/Sidebar';
import { useAppSelector } from '../shared/store/store';
import { useEffect, useState } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import useClientService from '../shared/services/useClientService';
import React from 'react';
import { useIdleTimer } from 'react-idle-timer';
import { Dialog } from 'primereact/dialog';
import { motion } from 'framer-motion';
import { fadeIn } from '../shared/helpers/animation';
import Button from '../shared/components/Button';
import { useAuth0 } from '@auth0/auth0-react';
import useWatchSettingsService from '../shared/services/useWatchSettingsService';

const Dashboard: React.FC = () => {
  const { logout } = useAuth0();
  // handle client
  const { client } = useAppSelector((state) => state.client);
  const { id } = useParams();
  const { getClientById } = useClientService();
  const { getWatchSettings } = useWatchSettingsService();
  useEffect(() => {
    if (id) {
      getClientById(id);
      getWatchSettings(id);
    }
  }, [id]);

  // handle logout timer
  // Set timeout values
  const timeout = 1000 * 60 * 10;
  const promptTimeout = 1000 * 30;

  // Modal open state
  const [open, setOpen] = useState(false);

  // Time before idle
  const [remaining, setRemaining] = useState(0);

  const onPrompt = () => {
    // onPrompt will be called after the timeout value is reached
    // In this case 30 minutes. Here you can open your prompt.
    // All events are disabled while the prompt is active.
    // If the user wishes to stay active, call the `reset()` method.
    // You can get the remaining prompt time with the `getRemainingTime()` method,
    setOpen(true);
    setRemaining(promptTimeout);
  };

  const onIdle = () => {
    // onIdle will be called after the promptTimeout is reached.
    // In this case 30 seconds. Here you can close your prompt and
    // perform what ever idle action you want such as log out your user.
    // Events will be rebound as long as `stopOnMount` is not set.
    setOpen(false);
    setRemaining(0);
    logout({ returnTo: window.location.origin });
  };

  const onActive = () => {
    // onActive will only be called if `reset()` is called while `isPrompted()`
    // is true. Here you will also want to close your modal and perform
    // any active actions.
    setOpen(false);
    setRemaining(0);
  };
  const { getRemainingTime, isPrompted, reset } = useIdleTimer({
    timeout,
    promptTimeout,
    onPrompt,
    onIdle,
    onActive,
  });

  const handleStillHere = () => {
    setOpen(false);
    reset();
  };

  useEffect(() => {
    const interval = setInterval(() => {
      if (isPrompted()) {
        setRemaining(Math.ceil(getRemainingTime() / 1000));
      }
    }, 1000);
    return () => {
      clearInterval(interval);
    };
  }, [getRemainingTime, isPrompted]);

  const links: SidebarLink[] = [
    {
      to: '/dashboard/client-list',
      label: 'Client List',
      icon: 'pi pi-users',
    },
    {
      to: `/dashboard/client/${client?.id}/charts`,
      label: 'Dashboard',
      icon: 'pi-chart-bar',
    },
    {
      to: `/dashboard/client/${client?.id}/table`,
      label: 'Data Table',
      icon: 'pi pi-table',
    },
    {
      to: `/dashboard/client/${client?.id}/watch-settings`,
      label: 'Watch Settings',
      icon: 'pi-cog',
    },
  ];
  return (
    <>
      <SidebarLayout links={links}>
        <Outlet />
      </SidebarLayout>
      <Dialog
        visible={open}
        onHide={handleStillHere}
        showHeader={false}
        className="rounded-lg"
      >
        <div className="px-6 py-4">
          <header className="my-4 flex items-center justify-center">
            <h2 className="text-2xl font-bold">Are you still here?</h2>
          </header>
          <motion.main
            className="mx-auto flex max-w-md flex-col items-stretch justify-between gap-y-2"
            variants={fadeIn}
            initial="hidden"
            animate="visible"
          >
            <p className="text-center">You have been idle for 10 minutes.</p>
            <p className="text-center">
              Stay logged in to continue using Caloosha.
            </p>
            <div className="mt-4 flex justify-center gap-x-4">
              <Button
                label="Logout"
                className="p-button-outlined p-button-info"
                onClick={onIdle}
              />
              <Button
                label="Stay Logged In"
                className="p-button-info"
                onClick={handleStillHere}
              />
            </div>
          </motion.main>
        </div>
      </Dialog>
    </>
  );
};

export default React.memo(Dashboard);
