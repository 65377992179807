// Converts a string to an ArrayBuffer
export const stringToArrayBuffer = (str: string) => {
  var buf = new ArrayBuffer(str.length);
  var bufView = new Uint8Array(buf);
  for (var i = 0, strLen = str.length; i < strLen; i++)
    bufView[i] = str.charCodeAt(i);
  return buf;
};

export const arrayBufferToString = (buf: ArrayBuffer) => {
  // @ts-ignore - new Uint8Array(buf) is correct
  return String.fromCharCode.apply(null, new Uint8Array(buf));
};

export const atob = (input: string) => {
  // Copied from https://github.com/strophe/strophejs/blob/e06d027/src/polyfills.js#L149
  // This code was written by Tyler Akins and has been placed in the
  // public domain.  It would be nice if you left this header intact.
  // Base64 code from Tyler Akins -- http://rumkin.com
  var keyStr =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=';

  var output = '';
  var chr1, chr2, chr3;
  var enc1, enc2, enc3, enc4;
  var i = 0;
  // remove all characters that are not A-Z, a-z, 0-9, +, /, or =
  input = input.replace(/[^A-Za-z0-9\+\/\=]/g, '');
  do {
    enc1 = keyStr.indexOf(input.charAt(i++));
    enc2 = keyStr.indexOf(input.charAt(i++));
    enc3 = keyStr.indexOf(input.charAt(i++));
    enc4 = keyStr.indexOf(input.charAt(i++));

    chr1 = (enc1 << 2) | (enc2 >> 4);
    chr2 = ((enc2 & 15) << 4) | (enc3 >> 2);
    chr3 = ((enc3 & 3) << 6) | enc4;

    output = output + String.fromCharCode(chr1);

    if (enc3 !== 64) {
      output = output + String.fromCharCode(chr2);
    }
    if (enc4 !== 64) {
      output = output + String.fromCharCode(chr3);
    }
  } while (i < input.length);
  return output;
};

// btoa that works on utf8
export function btoa(input: string) {
  var b64 = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/';
  var out = '';
  var i = 0;
  while (i < input.length) {
    var octet_a = 0 | input.charCodeAt(i++);
    var octet_b = 0;
    var octet_c = 0;
    var padding = 0;
    if (i < input.length) {
      octet_b = 0 | input.charCodeAt(i++);
      if (i < input.length) {
        octet_c = 0 | input.charCodeAt(i++);
        padding = 0;
      } else padding = 1;
    } else padding = 2;
    var triple = (octet_a << 0x10) + (octet_b << 0x08) + octet_c;
    out +=
      b64[(triple >> 18) & 63] +
      b64[(triple >> 12) & 63] +
      (padding > 1 ? '=' : b64[(triple >> 6) & 63]) +
      (padding > 0 ? '=' : b64[triple & 63]);
  }
  return out;
}

/// Parse and fix issues like `if (false)\n foo` in the root scope
export function reformatCode(code: string) {
  var APPLY_LINE_NUMBERS = false;
  var lineNumberOffset = 0;
  // var ENV = Espruino.Core.Env.getData();
  // if (ENV && ENV.VERSION_MAJOR && ENV.VERSION_MINOR) {
  //   if (ENV.VERSION_MAJOR>1 ||
  //       ENV.VERSION_MINOR>=81.086) {
  //     if (Espruino.Config.STORE_LINE_NUMBERS)
  //       APPLY_LINE_NUMBERS = true;
  //   }
  // }
  // Turn cr/lf into just lf (eg. windows -> unix)
  code = code.replace(/\r\n/g, '\n');
  // First off, try and fix funky characters
  for (var i = 0; i < code.length; i++) {
    var ch = code.charCodeAt(i);
    if (
      (ch < 32 || ch > 255) &&
      ch != 9 /*Tab*/ &&
      ch != 10 /*LF*/ &&
      ch != 13 /*CR*/
    ) {
      console.warn(
        'Funky character code ' +
          ch +
          ' at position ' +
          i +
          '. Replacing with ?',
      );
      code = code.substr(0, i) + '?' + code.substr(i + 1);
    }
  }

  /* Search for lines added to the start of the code by the module handler.
 Ideally there would be a better way of doing this so line numbers stayed correct,
 but this hack works for now. Fixes EspruinoWebIDE#140 */
  if (APPLY_LINE_NUMBERS) {
    var l = code.split('\n');
    var i = 0;
    while (
      l[i] &&
      (l[i].substr(0, 8) == 'Modules.' || l[i].substr(0, 8) == 'setTime(')
    )
      i++;
    lineNumberOffset = -i;
  }

  var resultCode = '\x10'; // 0x10 = echo off for line
  /** we're looking for:
   *   `a = \n b`
   *   `for (.....) \n X`
   *   `if (.....) \n X`
   *   `if (.....) { } \n else foo`
   *   `while (.....) \n X`
   *   `do \n X`
   *   `function (.....) \n X`
   *   `function N(.....) \n X`
   *   `var a \n , b`    `var a = 0 \n, b`
   *   `var a, \n b`     `var a = 0, \n b`
   *   `a \n . b`
   *   `foo() \n . b`
   *   `try { } \n catch \n () \n {}`
   *
   *   These are divided into two groups - where there are brackets
   *   after the keyword (statementBeforeBrackets) and where there aren't
   *   (statement)
   *
   *   We fix them by replacing \n with what you get when you press
   *   Alt+Enter (Ctrl + LF). This tells Espruino that it's a newline
   *   but NOT to execute.
   */
  var lex = getLexer(code);
  var brackets = 0;
  var curlyBrackets = 0;
  var statementBeforeBrackets = false;
  var statement = false;
  var varDeclaration = false;
  var lastIdx = 0;
  var lastTok = { str: '' };
  var tok = lex.next();
  while (tok !== undefined) {
    var previousString = code.substring(lastIdx, tok.startIdx);
    var tokenString = code.substring(tok.startIdx, tok.endIdx);
    //console.log("prev "+JSON.stringify(previousString)+"   next "+tokenString);

    /* Inserting Alt-Enter newline, which adds newline without trying
   to execute */
    if (
      brackets > 0 || // we have brackets - sending the alt-enter special newline means Espruino doesn't have to do a search itself - faster.
      statement || // statement was before brackets - expecting something else
      statementBeforeBrackets || // we have an 'if'/etc
      varDeclaration || // variable declaration then newline
      tok.str == ',' || // comma on newline - there was probably something before
      tok.str == '.' || // dot on newline - there was probably something before
      tok.str == '+' ||
      tok.str == '-' || // +/- on newline - there was probably something before
      tok.str == '=' || // equals on newline - there was probably something before
      tok.str == 'else' || // else on newline
      lastTok.str == 'else' || // else befgore newline
      tok.str == 'catch' || // catch on newline - part of try..catch
      lastTok.str == 'catch'
    ) {
      //console.log("Possible"+JSON.stringify(previousString));
      previousString = previousString.replace(/\n/g, '\x1B\x0A');
    }

    var previousBrackets = brackets;
    if (tok.str == '(' || tok.str == '{' || tok.str == '[') brackets++;
    if (tok.str == '{') curlyBrackets++;
    if (tok.str == ')' || tok.str == '}' || tok.str == ']') brackets--;
    if (tok.str == '}') curlyBrackets--;

    if (brackets == 0) {
      if (
        tok.str == 'for' ||
        tok.str == 'if' ||
        tok.str == 'while' ||
        tok.str == 'function' ||
        tok.str == 'throw'
      ) {
        statementBeforeBrackets = true;
        varDeclaration = false;
      } else if (tok.str == 'var') {
        varDeclaration = true;
      } else if (tok.type == 'ID' && lastTok.str == 'function') {
        statementBeforeBrackets = true;
      } else if (tok.str == 'try' || tok.str == 'catch') {
        statementBeforeBrackets = true;
      } else if (tok.str == ')' && statementBeforeBrackets) {
        statementBeforeBrackets = false;
        statement = true;
      } else if (
        [
          '=',
          '^',
          '&&',
          '||',
          '+',
          '+=',
          '-',
          '-=',
          '*',
          '*=',
          '/',
          '/=',
          '%',
          '%=',
          '&',
          '&=',
          '|',
          '|=',
        ].indexOf(tok.str) >= 0
      ) {
        statement = true;
      } else {
        if (tok.str == ';') varDeclaration = false;
        statement = false;
        statementBeforeBrackets = false;
      }
    }
    /* If we're at root scope and had whitespace/comments between code,
   remove it all and replace it with a single newline and a
   0x10 (echo off for line) character. However DON'T do this if we had
   an alt-enter in the line, as it was there to stop us executing
   prematurely */
    if (
      previousBrackets == 0 &&
      previousString.indexOf('\n') >= 0 &&
      previousString.indexOf('\x1B\x0A') < 0
    ) {
      previousString = '\n\x10';
      // Apply line numbers to each new line sent, to aid debugger
      if (
        APPLY_LINE_NUMBERS &&
        tok.lineNumber &&
        tok.lineNumber + lineNumberOffset > 0
      ) {
        // Esc [ 1234 d
        // This is the 'set line number' command that we're abusing :)
        previousString +=
          '\x1B\x5B' + (tok.lineNumber + lineNumberOffset) + 'd';
      }
    }

    // add our stuff back together
    resultCode += previousString + tokenString;
    // next
    lastIdx = tok.endIdx;
    lastTok = tok;
    tok = lex.next();
  }
  //console.log(resultCode);
  if (brackets > 0) {
    console.error(
      'You have more open brackets than close brackets. Please see the hints in the Editor window.',
    );
    return undefined;
  }
  if (brackets < 0) {
    console.error(
      'You have more close brackets than open brackets. Please see the hints in the Editor window.',
    );
    return undefined;
  }
  while (
    resultCode[resultCode.length - 2] != '\n' ||
    resultCode[resultCode.length - 1] != '\n'
  ) {
    resultCode += '\n';
  }
  return resultCode;
}

/** Get a Lexer to parse JavaScript - this is really very nasty right now and it doesn't lex even remotely properly.
 * It'll return {type:"type", str:"chars that were parsed", value:"string", startIdx: Index in string of the start, endIdx: Index in string of the end}, until EOF when it returns undefined */
function getLexer(str: string) {
  // Nasty lexer - no comments/etc
  var chAlpha = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz_$';
  var chNum = '0123456789';
  var chAlphaNum = chAlpha + chNum;
  var chWhiteSpace = ' \t\n\r';
  var chQuotes = '"\'`';
  let ch: any;
  var idx = 0;
  var lineNumber = 1;
  var nextCh = function () {
    ch = str[idx++];
    if (ch == '\n') lineNumber++;
  };
  nextCh();
  var isIn = function (s: string, c: string) {
    return s.indexOf(c) >= 0;
  };
  var nextToken: any = function () {
    while (isIn(chWhiteSpace, ch)) {
      nextCh();
    }
    if (ch == undefined) return undefined;
    if (ch == '/') {
      nextCh();
      if (ch == '/') {
        // single line comment
        while (ch !== undefined && ch != '\n') nextCh();
        return nextToken();
      } else if (ch == '*') {
        nextCh();
        var last = ch;
        nextCh();
        // multiline comment
        while (ch !== undefined && !(last == '*' && ch == '/')) {
          last = ch;
          nextCh();
        }
        nextCh();
        return nextToken();
      }
      return {
        type: 'CHAR',
        str: '/',
        value: '/',
        startIdx: idx - 2,
        endIdx: idx - 1,
        lineNumber: lineNumber,
      };
    }
    var s = '';
    var type, value;
    var startIdx = idx - 1;
    if (isIn(chAlpha, ch)) {
      // ID
      type = 'ID';
      do {
        s += ch;
        nextCh();
      } while (isIn(chAlphaNum, ch));
    } else if (isIn(chNum, ch)) {
      // NUMBER
      type = 'NUMBER';
      var chRange = chNum;
      if (ch == '0') {
        // Handle
        s += ch;
        nextCh();
        if ('xXoObB'.indexOf(ch) >= 0) {
          if (ch == 'b' || ch == 'B') chRange = '01';
          if (ch == 'o' || ch == 'O') chRange = '01234567';
          if (ch == 'x' || ch == 'X') chRange = '0123456789ABCDEFabcdef';
          s += ch;
          nextCh();
        }
      }
      while (isIn(chRange, ch) || ch == '.') {
        s += ch;
        nextCh();
      }
    } else if (isIn(chQuotes, ch)) {
      // STRING
      type = 'STRING';
      var q = ch;
      value = '';
      s += ch;
      nextCh();
      while (ch !== undefined && ch != q) {
        if (ch == '\\') {
          // handle escape characters
          nextCh();
          var escape = '\\' + ch;
          var escapeExtra = 0;
          if (ch == 'x') escapeExtra = 2;
          if (ch == 'u') escapeExtra = 4;
          // TODO: octal parsing? \123
          while (escapeExtra--) {
            nextCh();
            escape += ch;
          }
          s += escape;
          try {
            value += JSON.parse('"' + escape + '"');
          } catch (e) {
            value += escape;
          }
        } else {
          s += ch;
          value += ch;
        }
        nextCh();
      }
      if (ch !== undefined) s += ch;
      nextCh();
    } else {
      type = 'CHAR';
      s += ch;
      nextCh();
    }
    if (value === undefined) value = s;
    return {
      type: type,
      str: s,
      value: value,
      startIdx: startIdx,
      endIdx: idx - 1,
      lineNumber: lineNumber,
    };
  };

  return {
    next: nextToken,
  };
}

export function timeoutPromise(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}
