import { useState } from 'react';
import { slideDownFromTop } from '../../../shared/helpers/animation';
import { motion } from 'framer-motion';
import { ClientWithWatch } from '../../../shared/types';
import Button from '../../../shared/components/Button';
import useBluetooth from '../../../shared/bluetooth-module/useBluetooth';
import useWatchService from '../../../shared/services/useWatchService';
import BluetoothIcon from '../../../shared/components/BluetoothIcon';
import { useAppSelector } from '../../../shared/store/store';
import { useNavigate } from 'react-router-dom';

export default function DashboardHeader({
  client,
}: {
  client: ClientWithWatch;
}) {
  const [loadingData, setLoadingData] = useState(false);
  const { saveWatchData } = useWatchService();
  // Hooks
  const bluetooth = useBluetooth();
  const navigate = useNavigate();

  // Methods
  const downloadData = async () => {
    try {
      setLoadingData(true);
      navigate(`/dashboard/client/${client.id}/table`);
      const data = await bluetooth.downloadCalooshaData();
      await saveWatchData(client.watch, data);
      await bluetooth.eraseColooshaCSV();
    } finally {
      setLoadingData(false);
    }
  };
  return (
    <header className="mb-6 flex w-full items-center justify-between">
      <div>
        <motion.h1
          className="text-3xl font-bold"
          variants={slideDownFromTop}
          initial="hidden"
          animate="visible"
        >
          {client.firstName} {client.lastName}
        </motion.h1>
        <p className=" font-light text-neutral-500">Wearable Device Data</p>
      </div>
      <div className="flex flex-col">
        {client?.watch?.deviceName && (
          <span className="mb-2 font-light text-gray-800">
            Device: {client?.watch?.deviceName || 'No Device Available'}
          </span>
        )}
        <Button
          onClick={downloadData}
          loading={loadingData}
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
          }}
        >
          <BluetoothIcon color="#ffffff" className="mr-2" />
          Import new data from watch
        </Button>
      </div>
    </header>
  );
}
