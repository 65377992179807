import { CheckIcon, PencilIcon } from '@heroicons/react/solid';
import { motion } from 'framer-motion';

interface CmsEditButtonProps {
  editing: boolean;
  loading: boolean;
  handleClick: () => void;
}

const CmsEditButton = ({
  editing,
  loading,
  handleClick,
}: CmsEditButtonProps) => {
  return (
    <motion.button
      className={` flex items-center gap-x-1 rounded-lg  border-2 px-4 py-1 font-medium ${
        editing
          ? 'border-green-800 bg-green-100 text-green-800'
          : 'border-gray-600 bg-primary-light/20 text-gray-600'
      }`}
      whileTap={{ scale: 0.95 }}
      onClick={() => {
        handleClick();
      }}
    >
      {!loading ? (
        <>
          {editing ? (
            <CheckIcon className="h-5 w-5" />
          ) : (
            <PencilIcon className="h-5 w-5" />
          )}
          {editing ? 'Save changes' : 'Edit content'}
        </>
      ) : (
        <div className="flex items-center gap-x-2">
          <i className="pi pi-spin pi-spinner" style={{ fontSize: '1em' }}></i>
          Saving...
        </div>
      )}
    </motion.button>
  );
};

export default CmsEditButton;
