import { motion } from 'framer-motion';
import { useEffect, useState } from 'react';
import DatePicker from '../../../shared/components/DatePickerFloat';
import FormDropdown from '../../../shared/components/FormDropdown';
import FormInputFloat from '../../../shared/components/FormInputFloat';
import { fadeIn } from '../../../shared/helpers/animation';
import { useAppSelector } from '../../../shared/store/store';
import Button from '../../../shared/components/Button';

const NewClientStep1 = ({
  handleCloseModal,
  setActiveIndex,
  control,
  reset,
  errors,
  isValid,
}: {
  handleCloseModal: () => void;
  setActiveIndex: (index: number) => void;
  control: any;
  reset: () => void;
  errors: any;
  isValid: boolean;
}) => {
  const handleNext = () => {
    setActiveIndex(1);
  };

  const { serviceTypes } = useAppSelector((state) => state.storedConstants);
  const [serviceDropdownOptions, setServiceDropdownOptions] = useState<any>([]);

  useEffect(() => {
    if (serviceTypes) {
      setServiceDropdownOptions(
        serviceTypes.map((serviceType: any) => ({
          label: serviceType.name,
          value: serviceType.id,
        })),
      );
    }
  }, [serviceTypes]);

  return (
    <motion.main
      className="mx-auto flex h-[410px] max-w-md flex-col items-stretch justify-between gap-y-2"
      variants={fadeIn}
      initial="hidden"
      animate="visible"
    >
      <FormInputFloat
        label="First name"
        name="firstName"
        control={control}
        rules={{
          required: {
            value: true,
            message: 'First name is required',
          },
        }}
        errors={errors}
      />
      <FormInputFloat
        label="Last name"
        name="lastName"
        control={control}
        rules={{
          required: {
            value: true,
            message: 'Last name is required',
          },
        }}
        errors={errors}
      />
      <div className="flex gap-x-4">
        <DatePicker
          label="Date of birth"
          name="dateOfBirth"
          control={control}
          rules={{
            required: {
              value: true,
              message: 'Date of birth is required',
            },
          }}
          errors={errors}
          showIcon
        />
        <FormInputFloat
          label="Client ID (optional)"
          name="externalClientId"
          control={control}
          errors={errors}
        />
      </div>
      <FormDropdown
        label="Type of service"
        name="serviceTypeId"
        control={control}
        errors={errors}
        options={serviceDropdownOptions}
        rules={{
          required: {
            value: true,
            message: 'Category is required',
          },
        }}
      />
      <div className="flex gap-x-4">
        <Button
          label="Cancel"
          className="p-button-outlined p-button-info"
          onClick={() => handleCloseModal()}
        />
        <Button
          className="p-button-info grow"
          onClick={() => handleNext()}
          disabled={!isValid}
          label="Continue"
        />
      </div>
    </motion.main>
  );
};

export default NewClientStep1;
