import useAuthenticatedRequest from '../hooks/useAuthenticatedRequest';
import { Profile } from '../types';

const useCSVService = () => {
  const { fetchRequest } = useAuthenticatedRequest();
  const baseUrl = process.env.REACT_APP_API_URL;

  const downloadUserWatchDataCSV = async (profile: Profile) => {
    const response = await fetchRequest(
      `${baseUrl}/csv/download-user-watch-data-csv/${profile.id}`,
      {
        method: 'GET',
      },
    );
    downloadCSVFile(
      `${profile.firstName}-${
        profile.lastName
      }-${new Date().toISOString()}.csv`,
      response.data,
    );
  };

  const downloadAllWatchDataCSV = async () => {
    const response = await fetchRequest(
      `${baseUrl}/csv/download-all-watch-data-csv`,
      {
        method: 'GET',
      },
    );
    downloadCSVFile(`all-data-${new Date().toISOString()}.csv`, response.data);
  };

  const downloadCSVFile = (filename: string, csv: string) => {
    const blob = new Blob([csv], { type: 'text/plain' });
    const url = URL.createObjectURL(blob);
    // download the file
    const a = document.createElement('a');
    a.href = url;
    a.download = filename;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);
  };

  return {
    downloadUserWatchDataCSV,
    downloadAllWatchDataCSV,
  };
};

export default useCSVService;
