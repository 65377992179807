import { useAppDispatch } from '../store/store';
import useAuthenticatedRequest from '../hooks/useAuthenticatedRequest';
import * as actions from '../store/stored-constants';
import { useAuth0 } from '@auth0/auth0-react';

const useStoredConstantsService = () => {
  const { fetchRequest } = useAuthenticatedRequest();
  const dispatch = useAppDispatch();
  const baseUrl = process.env.REACT_APP_API_URL;
  const { user } = useAuth0();

  const getStoredConstants = () => {
    return Promise.all([getServiceTypes(), getHeartRateDefaults()]);
  };

  const getServiceTypes = async () => {
    if (!user) return;
    try {
      dispatch(actions.getServiceTypes());
      const serviceTypes = await fetchRequest(
        `${baseUrl}/stored-constants/service-types`,
        {
          method: 'GET',
        },
      );
      dispatch(actions.getServiceTypesSuccess(serviceTypes));
    } catch (error) {
      dispatch(actions.getServiceTypesFailure(error));
    }
  };

  const getHeartRateDefaults = async () => {
    if (!user) return;
    try {
      dispatch(actions.getHeartRateDefaults());
      const heartRateDefaults = await fetchRequest(
        `${baseUrl}/stored-constants/heart-rate-defaults`,
        {
          method: 'GET',
        },
      );
      dispatch(actions.getHeartRateDefaultsSuccess(heartRateDefaults));
    } catch (error) {
      dispatch(actions.getHeartRateDefaultsFailure(error));
    }
  };

  return {
    getHeartRateDefaults,
    getServiceTypes,
    getStoredConstants,
  };
};

export default useStoredConstantsService;
