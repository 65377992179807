import { useAuth0 } from '@auth0/auth0-react';
import { useNavigate } from 'react-router-dom';

// todo - define fetch params better
export interface FetchParams {
  method: 'GET' | 'POST' | 'PUT' | 'DELETE';
  params?: any;
  body?: any;
  headers?: any;
}
/**
 * Creates a FETCH api request with the token from Auth0
 *
 * Handles GET, DELETE, POST, and PUT
 * For GET and DELETE, params need to be supplied
 * for POST and PUT, a body needs to be supplied
 * If a user isn't authenticated, navigates to the application ROOT
 *
 * @returns
 */
const useAuthenticatedRequest = () => {
  const { getAccessTokenSilently, isAuthenticated, loginWithRedirect } =
    useAuth0();
  const navigate = useNavigate();

  const fetchRequest = async (url: string, fetchParams: FetchParams) => {
    try {
      if (!isAuthenticated) {
        throw new Error('User is not authenticated');
      }
      const token = await getAccessTokenSilently();
      if (!token) {
        throw new Error('Invalid token');
      }
      let response;
      const headers = {
        ...fetchParams.headers,
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      };
      switch (fetchParams.method) {
        case 'GET':
        case 'DELETE':
          const fetchUrl = new URL(url);
          fetchUrl.search = new URLSearchParams({
            ...fetchParams.params,
          }).toString();
          response = (await fetch(fetchUrl.toString(), {
            method: fetchParams.method,
            body: JSON.stringify(fetchParams.body),
            headers,
          })) as any;
          break;
        case 'POST':
        case 'PUT':
          response = (await fetch(url, {
            method: fetchParams.method,
            headers,
            body: JSON.stringify(fetchParams.body),
          })) as any;
          break;
        default:
          break;
      }
      if (!response) throw new Error('Unable to make request');
      const data = await response.json();
      if (data.statusCode && data.statusCode >= 400) {
        throw new Error(response.message || 'Unable to make request');
      }

      return data;
    } catch (error: any) {
      if (
        error.message === 'Invalid token' ||
        error.message === 'User is not authenticated'
      ) {
        navigate('/get-started');
      } else {
        console.error('error making request', error);
        throw error;
      }
    }
  };

  return {
    fetchRequest,
  };
};

export default useAuthenticatedRequest;
