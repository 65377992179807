import { ErrorMessage } from '@hookform/error-message';
import classNames from 'classnames';
import { Password } from 'primereact/password';
import { InputText } from 'primereact/inputtext';
import { Link } from 'react-router-dom';
import { Controller } from 'react-hook-form';
import { useEffect, useState } from 'react';

const FormInputFloat = ({
  control,
  errors,
  name,
  label,
  rules,
  type,
  inputRef,
  forgot,
}: {
  control: any;
  errors: any;
  name: string;
  label: string;
  rules?: any;
  type?: any;
  inputRef?: any;
  forgot?: boolean;
}) => {
  const [loaded, setLoaded] = useState(false);
  useEffect(() => {
    setLoaded(true);
  }, []);

  return (
    <div className="p-field">
      <span className="p-float-label">
        <Controller
          name={name}
          control={control}
          rules={rules}
          render={({ field, fieldState }) => {
            return type === 'password' ? (
              <>
                <Password
                  readOnly={!loaded}
                  id={name + '-input'}
                  {...field}
                  className={classNames('w-full', {
                    'p-invalid': fieldState.invalid,
                  })}
                  toggleMask
                  feedback={false}
                />
              </>
            ) : (
              <InputText
                id={name + '-input'}
                className={classNames('w-full', {
                  'p-invalid': fieldState.invalid,
                })}
                {...field}
                ref={inputRef}
              />
            );
          }}
        />
        <label htmlFor={name + '-input'}>{label}</label>
      </span>

      <div
        className={`mx-2 mb-4 flex h-4 w-full items-baseline pr-4 ${
          forgot ? 'justify-between' : 'justify-end'
        }`}
      >
        {forgot && (
          <p className="text-left">
            <Link
              to="/forgot-password"
              className="text-xs font-medium text-gray-400 underline"
            >
              Forgot Password?
            </Link>
          </p>
        )}
        <ErrorMessage
          errors={errors}
          name={name}
          render={({ message }) => (
            <small className="p-error p-d-block">{message}</small>
          )}
        />
      </div>
    </div>
  );
};
export default FormInputFloat;
