import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { motion } from 'framer-motion';
import { fadeIn } from '../../../shared/helpers/animation';
import FormInputFloat from '../../../shared/components/FormInputFloat';
import { emailValidator } from '../../../shared/helpers/form-validators';
import AnimatedLogoText from '../../../shared/components/AnimatedLogoText';
import Button from '../../../shared/components/Button';
import { useNavigate } from 'react-router-dom';
import PageTransitionWrapper from '../../../shared/components/PageTransitionWrapper';
import useProfileService from '../../../shared/services/useProfileService';
import { useAppSelector } from '../../../shared/store/store';
import { LoadingState } from '../../../shared/types';

const Register: React.FC = () => {
  const navigate = useNavigate();
  const { createProfile } = useProfileService();
  const { createProfileLoading } = useAppSelector((state) => state.profile);
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: 'onTouched',
    defaultValues: {
      firstName: '',
      lastName: '',
      organization: '',
    },
  });

  const handleRegister = async (data: any) => {
    createProfile(data);
  };

  useEffect(() => {
    if (createProfileLoading === LoadingState.LOADED) {
      navigate('/dashboard/client-list');
    }
    // TODO: Handle error here
  }, [createProfileLoading]);

  return (
    <PageTransitionWrapper>
      <main className="flex h-screen w-full items-center justify-center bg-gradient-to-bl from-cyan-400 to-cyan-900">
        <motion.div className=" flex w-3/4 flex-col items-center p-6 md:w-2/4 lg:w-2/6">
          <div className="w-2/4">
            <AnimatedLogoText color="white" />
          </div>
          <motion.div
            className="mt-8 sm:mx-auto sm:w-full sm:max-w-md"
            variants={fadeIn}
            initial="hidden"
            animate="visible"
          >
            <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
              <form className="space-y-6" action="#" method="POST">
                <h2 className="mb-8 text-2xl font-bold text-primary-light">
                  Create Therapist Account
                </h2>
                <motion.div variants={fadeIn}>
                  <FormInputFloat
                    label="First name"
                    name="firstName"
                    control={control}
                    rules={{
                      required: {
                        value: true,
                        message: 'First name is required',
                      },
                    }}
                    errors={errors}
                  />
                </motion.div>
                <motion.div variants={fadeIn}>
                  <FormInputFloat
                    label="Last name"
                    name="lastName"
                    control={control}
                    rules={{
                      required: {
                        value: true,
                        message: 'Last name is required',
                      },
                    }}
                    errors={errors}
                  />
                </motion.div>
                <motion.div variants={fadeIn}>
                  <FormInputFloat
                    label="Name of Organization"
                    name="organization"
                    control={control}
                    rules={{
                      required: {
                        value: true,
                        message: 'Organization name is required',
                      },
                    }}
                    errors={errors}
                  />
                </motion.div>

                <Button
                  label="Create account"
                  loading={createProfileLoading === LoadingState.LOADING}
                  className="p-button-info w-full"
                  onClick={handleSubmit((data) => handleRegister(data))}
                />
              </form>
            </div>
          </motion.div>
        </motion.div>
      </main>
    </PageTransitionWrapper>
  );
};

export default Register;
