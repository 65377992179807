import React, { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import useBluetooth from '../../../shared/bluetooth-module/useBluetooth';
import useClientService from '../../../shared/services/useClientService';
import useWatchSettingsService from '../../../shared/services/useWatchSettingsService';
import { showToast } from '../../../shared/store/alerts';
import { useAppDispatch, useAppSelector } from '../../../shared/store/store';
import { LoadingState, NewClient, SettingsData } from '../../../shared/types';
import NewClientFormHeader from '../NewClientFormHeader';
import NewClientStep1 from '../NewClientStep1';
import NewClientStep2 from '../NewClientStep2';
import NewClientStep3 from '../NewClientStep3';

const NewClientForm: React.FC<{
  handleCloseModal: () => void;
}> = ({ handleCloseModal }) => {
  const steps = [
    { label: ' Client info', header: 'Add a new client' },
    { label: 'Connect watch', header: "Pair new client's watch" },
    { label: 'Review', header: 'Confirm watch settings' },
  ];
  const [activeIndex, setActiveIndex] = useState(0);

  // form for first step
  const {
    control,
    reset,
    getValues,
    formState: { errors, isValid },
  } = useForm({
    mode: 'onTouched',
    defaultValues: {
      firstName: '',
      lastName: '',
      dateOfBirth: undefined as unknown as Date,
      serviceTypeId: '',
      externalClientId: '',
    },
  });

  // bluetooth for second step
  const [watchLoading, setWatchLoading] = useState(false);

  const bluetooth = useBluetooth();

  const handleConnectWatch = async () => {
    try {
      if (!getValues().dateOfBirth) return;
      const defaultHr = calculateDefaultHR(getValues().dateOfBirth);
      if (!defaultHr) return;
      if (!userProfile) return;
      setWatchLoading(true);
      const settings: SettingsData = {
        restingHr: defaultHr.bpmAverage,
        triggerHr: defaultHr.bpmAverage * 2, // trigger is 2x the average
        // the following values may need to be defined by the client
        timeoutAfterNo: 5,
        timeoutAfterIgnore: 5,
        timeoutAfterSuccess: 5,
        numberOfCycles: 4,
      };
      await bluetooth.saveWatchSettings(settings);
    } finally {
      setWatchLoading(false);
    }
  };

  // create client for last step
  const { userProfile } = useAppSelector((state) => state.profile);
  const { createClientLoading } = useAppSelector((state) => state.client);
  const { createClient } = useClientService();
  const { calculateDefaultHR } = useWatchSettingsService();
  const [btLoading, setBtLoading] = useState(false);
  const dispatch = useAppDispatch();
  useEffect(() => {
    if (createClientLoading === LoadingState.LOADED) {
      handleCloseModal();
    }
  }, [createClientLoading]);

  const handleCreateClient = async () => {
    try {
      if (!getValues().dateOfBirth) return;
      const defaultHr = calculateDefaultHR(getValues().dateOfBirth);
      if (!defaultHr) return;
      if (!userProfile) return;
      setBtLoading(true);
      const settings: SettingsData = {
        restingHr: defaultHr.bpmAverage,
        triggerHr: defaultHr.bpmAverage * 2, // trigger is 2x the average
        // the following values may need to be defined by the client
        timeoutAfterNo: 5,
        timeoutAfterIgnore: 5,
        timeoutAfterSuccess: 5,
        numberOfCycles: 4,
      };
      await createClient(
        {
          ...getValues(),
          therapistId: userProfile.id,
        },
        bluetooth.device,
        settings,
      );
      setBtLoading(false);
    } catch (error: any) {
      dispatch(
        showToast({
          summary: 'Error creating client',
          severity: 'error',
          detail: error.message || 'Unable to create client, please try again',
        }),
      );
    }
  };

  return (
    <div className="mx-auto flex w-max flex-col">
      <NewClientFormHeader
        handleCloseModal={handleCloseModal}
        steps={steps}
        activeIndex={activeIndex}
        setActiveIndex={setActiveIndex}
      />
      {activeIndex === 0 && (
        <NewClientStep1
          handleCloseModal={handleCloseModal}
          setActiveIndex={setActiveIndex}
          control={control}
          reset={reset}
          errors={errors}
          isValid={isValid}
        />
      )}
      {activeIndex === 1 && (
        <NewClientStep2
          setActiveIndex={setActiveIndex}
          handleConnectWatch={handleConnectWatch}
          watchLoading={watchLoading}
          btDevice={bluetooth.device}
        />
      )}
      {activeIndex === 2 && (
        <NewClientStep3
          setActiveIndex={setActiveIndex}
          handleSubmit={() => handleCreateClient()}
          loading={createClientLoading === LoadingState.LOADING || btLoading}
        />
      )}
    </div>
  );
};

export default NewClientForm;
