import { motion } from 'framer-motion';
import Button from '../../../shared/components/Button';
import { NewClient } from '../../../shared/types';

const NewClientStep3 = ({
  setActiveIndex,
  handleSubmit,
  loading,
}: {
  setActiveIndex: (index: number) => void;
  handleSubmit: () => void;
  loading: boolean;
}) => {
  return (
    <motion.main className="flex h-[410px] flex-col justify-between">
      <div className="max-w-md text-lg">
        <p>
          Caloosha uses your client’s birth date to provide a default “trigger
          heart rate.” This is the heart rate that will cause the watch to tell
          your client that a breathing exercise is necessary.
        </p>
        <p className="mt-4">
          You’ll be able to customize & confirm these settings on the next
          screen.
        </p>
      </div>
      <div className="flex w-full gap-x-4">
        <Button
          className="p-button-outlined p-button-info w-max"
          onClick={() => setActiveIndex(1)}
          label="Back"
        />
        <Button
          className="p-button-info grow"
          onClick={() => handleSubmit()}
          loading={loading}
          label="Continue"
        />
      </div>
    </motion.main>
  );
};

export default NewClientStep3;
