import FullPageLoading from '../../../shared/components/FullPageLoading';
import { useAppSelector } from '../../../shared/store/store';
import { LoadingState } from '../../../shared/types';
import WatchDataTable from './WatchDataTable';
import DashboardHeader from '../../components/DashboardHeader';

const Table = () => {
  // state
  const { client, clientLoading } = useAppSelector((state) => state.client);

  return clientLoading === LoadingState.LOADED && client ? (
    <div className="h-full p-8">
      <DashboardHeader client={client} />
      <main className="mt-6">
        <WatchDataTable />
      </main>
    </div>
  ) : (
    <>
      <FullPageLoading />
    </>
  );
};

export default Table;
