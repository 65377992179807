import './App.css';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import Dashboard from './Dashboard';
import ClientList from './Dashboard/pages/ClientList';
import WatchSettings from './Dashboard/pages/WatchSettings';
import { AnimatePresence, MotionConfig } from 'framer-motion';
import Register from './auth-module/pages/Register';
import GetStarted from './auth-module/pages/GetStarted';
import AppToast from './shared/components/AppToast';
import FrequentlyAskedQuestions from './Dashboard/pages/FrequentlyAskedQuestions';
import Charts from './Dashboard/pages/Charts';
import AuthGuard from './shared/components/AuthGuard';
import ProfileGuard from './shared/components/ProfileGuard';
import Table from './Dashboard/pages/Table';
import AdminDashboard from './Dashboard/pages/AdminDashboard';

function App() {
  const location = useLocation();

  return (
    <>
      <MotionConfig reducedMotion="user">
        <AnimatePresence exitBeforeEnter>
          <Routes location={location} key={location.pathname.split('/')[1]}>
            <Route
              path="/dashboard"
              element={
                <AuthGuard>
                  <ProfileGuard>
                    <Dashboard />
                  </ProfileGuard>
                </AuthGuard>
              }
            >
              <Route path="/dashboard/admin" element={<AdminDashboard />} />
              <Route path="client-list" element={<ClientList />} />
              <Route
                path="client/:id/watch-settings"
                element={<WatchSettings />}
              />
              <Route path="client/:id/charts" element={<Charts />} />
              <Route path="client/:id/table" element={<Table />} />
              <Route path="faq" element={<FrequentlyAskedQuestions />} />
            </Route>
            <Route
              path="/register"
              element={
                <AuthGuard>
                  <ProfileGuard>
                    <Register />
                  </ProfileGuard>
                </AuthGuard>
              }
            />

            <Route path="/get-started" element={<GetStarted />} />

            <Route
              path="*"
              element={<Navigate to="/dashboard/client-list" replace />}
            />
          </Routes>
        </AnimatePresence>
      </MotionConfig>
      <AppToast />
    </>
  );
}

export default App;
