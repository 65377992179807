import { motion } from 'framer-motion';
const animationConfiguration = {
  initial: { opacity: 0 },
  animate: {
    opacity: 1,
    transition: {
      when: 'beforeChildren',
      staggerChildren: 0.5,
    },
  },
  exit: {
    opacity: 0,
    transition: {
      when: 'afterChildren',
      duration: 0.7,
    },
  },
};
const PageTransitionWrapper: React.FC<{ children: any }> = ({ children }) => {
  return (
    <motion.div
      variants={animationConfiguration}
      initial={false}
      animate="animate"
      exit="exit"
    >
      {children}
    </motion.div>
  );
};
export default PageTransitionWrapper;
