import { useAuth0 } from '@auth0/auth0-react';
import { useEffect } from 'react';
import { RouteProps, Navigate } from 'react-router-dom';
import useProfileService from '../../services/useProfileService';
import useStoredConstantsService from '../../services/useStoredConstantsService';
import FullPageLoading from '../FullPageLoading';

const AuthGuard: React.FC<RouteProps> = ({ children }) => {
  const { user, isAuthenticated, isLoading, logout, getAccessTokenSilently } =
    useAuth0();
  const { getProfileByEmail, updateProfile } = useProfileService();
  const { getStoredConstants } = useStoredConstantsService();

  useEffect(() => {
    if (!isLoading) {
      if (user?.email) {
        /**
         * get current profile.  Last login handled in profile guard
         * because profile will be null for first time users
         */
        getProfileByEmail(user.email);
        getStoredConstants();
      } else {
        // check for token, logout if there isn't an email but there is a token
        checkToken();
      }
    }
  }, [isLoading, isAuthenticated, user]);

  const checkToken = async () => {
    // gets token
    const token = await getAccessTokenSilently({
      audience: process.env.REACT_APP_AUTH0_AUDIENCE,
      scope: process.env.REACT_APP_AUTH0_SCOPE,
    });
    // if there is a token, logout.  There is no email, so the user should not be logged in.
    if (token) {
      logout({ returnTo: window.location.origin });
    }
  };

  return isLoading ? (
    <FullPageLoading />
  ) : isAuthenticated ? (
    <>{children}</>
  ) : (
    <Navigate to="/get-started" />
  );
};

export default AuthGuard;
