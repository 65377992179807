import { ReactNode, useEffect, useRef, useState } from 'react';
import Sidebar from './Sidebar';
import { SidebarLink } from './Sidebar';
import AnimatedLogoText from '../../../shared/components/AnimatedLogoText';
import { motion } from 'framer-motion';
import {
  slideInFromLeft,
  slideUpFromBottom,
} from '../../../shared/helpers/animation';
import { useAppSelector } from '../../../shared/store/store';
import {
  ChevronDownIcon,
  UserIcon,
  QuestionMarkCircleIcon,
} from '@heroicons/react/solid';
import { Menu } from 'primereact/menu';
import { useAuth0 } from '@auth0/auth0-react';
import { useNavigate } from 'react-router-dom';
import useIsAdmin from '../../../shared/hooks/useIsAdmin';

const SidebarLayout: React.FC<{
  links: SidebarLink[];
  children: ReactNode;
}> = ({ links, children }) => {
  const { logout } = useAuth0();
  const { isAdmin } = useIsAdmin();
  const { userProfile } = useAppSelector((state) => state.profile);
  const [hover, setHover] = useState(false);
  const navigate = useNavigate();
  const accountEnter = () => {
    setHover(true);
  };
  const accountLeave = () => {
    setHover(false);
  };

  let items = [
    {
      label: 'Log out',
      icon: 'pi pi-fw pi-sign-out',
      command: () => {
        logout({ returnTo: window.location.origin });
      },
    },
  ];

  const adminItems = [
    {
      label: 'Admin dashboard',
      icon: 'pi pi-fw pi-user',
      command: () => {
        navigate('/dashboard/admin');
      },
    },
    ...items,
  ];

  const menu = useRef<any>(null);
  return (
    <div className="relative grid h-screen grid-cols-[min-content_1fr]">
      <motion.div variants={slideInFromLeft} initial="hidden" animate="visible">
        <Sidebar links={links} />
      </motion.div>
      <div className="grid h-screen grid-rows-[60px_1fr]">
        <header className="flex items-center justify-between bg-sky-100 px-4 py-2">
          <div className="w-36">
            <AnimatedLogoText />
          </div>
          {isAdmin && (
            <p className="font-branded text-sm font-bold text-primary-light/50">
              ADMIN
            </p>
          )}
          <div className="flex">
            <div
              className="mr-8 flex cursor-pointer items-center gap-x-2"
              onClick={() => navigate('/dashboard/faq')}
            >
              <QuestionMarkCircleIcon className="h-4 w-4 text-neutral-400" />
              <motion.p className="mt-1  font-branded font-bold text-neutral-400">
                FAQ
              </motion.p>
            </div>
            <div
              className="mr-4 flex cursor-pointer items-center gap-x-2"
              onMouseEnter={() => accountEnter()}
              onMouseLeave={() => accountLeave()}
              onClick={(event) => menu.current.toggle(event)}
              aria-controls="popup_menu"
              aria-haspopup
            >
              <UserIcon className="h-4 w-4 text-neutral-400" />
              <motion.p
                className="mt-1  font-branded font-bold text-neutral-400"
                layout
              >
                {userProfile?.firstName} {userProfile?.lastName}
              </motion.p>
              <ChevronDownIcon className=" h-4 w-4 text-neutral-400" />
            </div>
          </div>
          <Menu
            model={isAdmin ? adminItems : items}
            popup
            ref={menu}
            id="popup_menu"
          />
        </header>
        <main className="flex max-h-full flex-col overflow-scroll">
          <div className="grow">{children}</div>
          <motion.footer
            className="flex justify-between border-t-2 border-gray-100 bg-white p-4"
            variants={slideUpFromBottom}
            initial="hidden"
            animate="visible"
          >
            <span className="bg-white text-sm font-bold text-gray-500">
              Scaffold
            </span>
            <span className="bg-white text-sm font-bold text-gray-500">
              © 2022 | All Rights Reserved
            </span>
          </motion.footer>
        </main>
      </div>
    </div>
  );
};

export default SidebarLayout;
