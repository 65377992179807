import { createSlice } from '@reduxjs/toolkit';
import { CallState, LoadingState } from '../types';
import { Profile } from '../types';

export interface ProfileState {
  userProfile: Profile | null;
  allProfiles: Profile[] | [];
  allProfilesLoading: CallState;
  profileLoading: CallState;
  updateProfileLoading: CallState;
  createProfileLoading: CallState;
}

export const profileSlice = createSlice({
  name: 'profile',
  initialState: {
    userProfile: null,
    allProfiles: [],
    allProfilesLoading: LoadingState.INIT,
    profileLoading: LoadingState.INIT,
    createProfileLoading: LoadingState.INIT,
  } as ProfileState,
  reducers: {
    getProfileByEmail: (state) => {
      state.profileLoading = LoadingState.LOADING;
    },
    getProfileByEmailSuccess: (state, { payload }) => {
      state.profileLoading = LoadingState.LOADED;
      state.userProfile = payload;
    },
    getProfileByEmailFailure: (state, { payload }) => {
      state.profileLoading = { error: payload };
    },
    createProfile: (state) => {
      state.createProfileLoading = LoadingState.LOADING;
    },
    createProfileSuccess: (state, { payload }) => {
      state.createProfileLoading = LoadingState.LOADED;
      state.userProfile = payload;
    },
    createProfileFailure: (state, { payload }) => {
      state.createProfileLoading = { error: payload };
    },
    getAllProfiles: (state) => {
      state.allProfilesLoading = LoadingState.LOADING;
    },
    getAllProfilesSuccess: (state, { payload }) => {
      state.allProfilesLoading = LoadingState.LOADED;
      state.allProfiles = payload;
    },
    getAllProfilesFailure: (state, { payload }) => {
      state.allProfilesLoading = { error: payload };
    },
    updateProfile: (state) => {
      state.updateProfileLoading = LoadingState.LOADING;
    },
    updateProfileSuccess: (state, { payload }) => {
      state.updateProfileLoading = LoadingState.LOADED;
      state.userProfile = payload;
    },
    updateProfileFailure: (state, { payload }) => {
      state.updateProfileLoading = { error: payload };
    },
  },
});

export const {
  getProfileByEmail,
  getProfileByEmailSuccess,
  getProfileByEmailFailure,
  createProfile,
  createProfileSuccess,
  createProfileFailure,
  getAllProfiles,
  getAllProfilesSuccess,
  getAllProfilesFailure,
  updateProfile,
  updateProfileSuccess,
  updateProfileFailure,
} = profileSlice.actions;

export default profileSlice.reducer;
