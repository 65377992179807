import { motion } from 'framer-motion';
import React from 'react';
import { XIcon } from '@heroicons/react/solid';

interface CmsDeleteButtonProps {
  handleClick: () => void;
  loading: boolean;
}

const CmsDeleteButton = ({ handleClick, loading }: CmsDeleteButtonProps) => {
  const [showDoubleCheck, setShowDoubleCheck] = React.useState(false);

  const handleFirstClick = () => {
    if (!showDoubleCheck) {
      setShowDoubleCheck(true);
    } else {
      handleClick();
      setShowDoubleCheck(false);
    }
  };
  return (
    <motion.button
      className="flex items-center gap-x-1 rounded-lg  border-2 border-red-800 bg-red-100 px-4 py-1 font-medium text-red-800"
      whileTap={{ scale: 0.95 }}
      onClick={() => {
        handleFirstClick();
      }}
    >
      {!loading ? (
        <>
          <XIcon className="h-5 w-5" />
          {!showDoubleCheck
            ? 'Delete question'
            : 'Are you sure? Click again to delete.'}
        </>
      ) : (
        <div className="flex items-center gap-x-2">
          <i className="pi pi-spin pi-spinner" style={{ fontSize: '1em' }}></i>
          Deleting...
        </div>
      )}
    </motion.button>
  );
};

export default CmsDeleteButton;
