import { useEffect, useState } from 'react';
import { NavLink, useLocation, useParams } from 'react-router-dom';
import { SidebarLink } from '../Sidebar';
import { motion } from 'framer-motion';

const SidebarListItem: React.FC<{ link: SidebarLink; expanded: boolean }> = ({
  link,
  expanded,
}) => {
  const [active, setActive] = useState<boolean>(false);
  const location = useLocation();

  useEffect(() => {
    const path = location.pathname;
    if (path.includes(link.to)) {
      setActive(true);
    } else {
      setActive(false);
    }
  }, [location]);

  const childAnimation = {
    visible: {
      opacity: 100,
      transition: {
        delay: 0.3,
        duration: 1,
      },
    },
    hidden: {
      opacity: 0,
    },
  };

  return (
    <motion.li
      className={`hover:bg-dark-primary m-2 flex cursor-pointer items-center rounded-lg border-2 p-4 transition-all ${
        expanded ? 'justify-start' : 'justify-center'
      }  ${active ? 'border-secondary bg-primary-dark' : 'border-transparent'}`}
    >
      <NavLink
        to={link.to}
        className={`baseline flex h-[18px] items-center justify-start text-base`}
      >
        <i className={`pi ${link.icon}`} style={{ fontSize: '1.2em' }}></i>
        {expanded && (
          <motion.p
            variants={childAnimation}
            animate="visible"
            initial="hidden"
            className="ml-2 mt-1 font-branded font-bold"
          >
            {link.label}
          </motion.p>
        )}
      </NavLink>
    </motion.li>
  );
};

export default SidebarListItem;
