import { createSlice } from '@reduxjs/toolkit';
import { CallState, ClientWithWatch, LoadingState } from '../types';

export interface ClientState {
  client: ClientWithWatch | null;
  clientLoading: CallState;
  clients: ClientWithWatch[];
  clientsLoading: CallState;
  createClientLoading: CallState;
}

export const clientSlice = createSlice({
  name: 'client',
  initialState: {
    client: null,
    clientLoading: LoadingState.INIT,
    clients: [],
    clientsLoading: LoadingState.INIT,
    createClientLoading: LoadingState.INIT,
  } as ClientState,
  reducers: {
    getClientById: (state) => {
      state.clientLoading = LoadingState.LOADING;
    },
    getClientByIdSuccess: (state, { payload }) => {
      state.clientLoading = LoadingState.LOADED;
      state.client = payload;
    },
    getClientByIdFailure: (state, { payload }) => {
      state.clientLoading = { error: payload };
    },
    createClient: (state) => {
      state.createClientLoading = LoadingState.LOADING;
    },
    createClientSuccess: (state, { payload }) => {
      state.createClientLoading = LoadingState.LOADED;
      state.client = payload;
    },
    createClientFailure: (state, { payload }) => {
      state.createClientLoading = { error: payload };
    },
    getAllClientsByTherapistId: (state) => {
      state.clientsLoading = LoadingState.LOADING;
    },
    getAllClientsByTherapistIdSuccess: (state, { payload }) => {
      state.clientsLoading = LoadingState.LOADED;
      state.clients = payload;
    },
    getAllClientsByTherapistIdFailure: (state, { payload }) => {
      state.clientLoading = { error: payload };
    },
  },
});

export const {
  getClientById,
  getClientByIdSuccess,
  getClientByIdFailure,
  createClient,
  createClientSuccess,
  createClientFailure,
  getAllClientsByTherapistId,
  getAllClientsByTherapistIdSuccess,
  getAllClientsByTherapistIdFailure,
} = clientSlice.actions;

export default clientSlice.reducer;
