import { ErrorMessage } from '@hookform/error-message';
import classNames from 'classnames';
import { Password } from 'primereact/password';
import { InputText } from 'primereact/inputtext';
import { Link } from 'react-router-dom';
import { Controller } from 'react-hook-form';
import { useEffect, useState } from 'react';

const FormInput = ({
  control,
  errors,
  name,
  rules,
  type = 'text',
  inputRef,
  className,
}: {
  control: any;
  errors: any;
  name: string;
  rules?: any;
  type?: any;
  inputRef?: any;
  className?: string;
}) => {
  const [loaded, setLoaded] = useState(false);
  useEffect(() => {
    setLoaded(true);
  }, []);

  return (
    <div className="p-field">
      <Controller
        name={name}
        control={control}
        rules={rules}
        render={({ field, fieldState }) => {
          return (
            <InputText
              id={name + '-input'}
              className={classNames('w-full', className, {
                'p-invalid': fieldState.invalid,
              })}
              {...field}
              ref={inputRef}
              type={type}
            />
          );
        }}
      />
      <div className="mx-2 mb-4 flex h-4 w-full items-baseline pr-4">
        <ErrorMessage
          errors={errors}
          name={name}
          render={({ message }) => (
            <small className="p-error p-d-block">{message}</small>
          )}
        />
      </div>
    </div>
  );
};
export default FormInput;
