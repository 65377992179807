import { motion } from 'framer-motion';
import Button from '../../../shared/components/Button';
import { InputText } from 'primereact/inputtext';
import { useEffect, useState } from 'react';
import { slideDownFromTop, fadeIn } from '../../../shared/helpers/animation';
import { Dialog } from 'primereact/dialog';
import NewClientForm from '../../components/NewClientForm';
import useClientService from '../../../shared/services/useClientService';
import { useAppSelector } from '../../../shared/store/store';
import { Client } from '../../../shared/types';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { FilterMatchMode } from 'primereact/api';
import { useNavigate } from 'react-router-dom';
import React from 'react';

const ClientList = () => {
  //Hooks
  const { getAllClientsByTherapistId } = useClientService();
  const navigate = useNavigate();

  //State
  const { clients } = useAppSelector((state) => state.client);
  const [filters, setFilters] = useState<any>(null);
  const [searchText, setSearchText] = useState('');
  const [showModal, setShowModal] = useState(false);

  //useEffects
  useEffect(() => {
    getAllClientsByTherapistId();
  }, []);

  useEffect(() => {
    initFilters();
  }, []);

  //Functions
  const onGlobalFilterChange = (e: any) => {
    const value = e.target.value;
    let _filters = { ...filters };
    _filters['global'].value = value;

    setFilters(_filters);
    setSearchText(value);
  };

  const initFilters = () => {
    setFilters({
      global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    });
    setSearchText('');
  };

  const clearSearch = () => {
    initFilters();
  };

  const handleOpenModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const onRowSelect = (event: any) => {
    navigate(`/dashboard/client/${event.data.id}/charts`);
  };

  const handleDownloadClients = () => {
    getAllClientsByTherapistId();
  };

  return (
    <div className="flex w-full flex-col gap-y-8 p-8">
      <motion.h1
        className="text-3xl font-bold"
        variants={slideDownFromTop}
        initial="hidden"
        animate="visible"
      >
        Client List
      </motion.h1>
      <div className="w-3/4 min-w-max lg:w-2/4">
        <div className="flex justify-between">
          <span
            className={`p-input-icon-left ${
              searchText !== '' && 'p-input-icon-right'
            }`}
          >
            <i className="pi pi-search" />
            <InputText
              value={searchText}
              onChange={onGlobalFilterChange}
              placeholder="Search"
            />
            {searchText !== '' && (
              <motion.i
                className="pi pi-times cursor-pointer"
                onClick={() => clearSearch()}
              />
            )}
          </span>
          <div className="flex items-center gap-x-2">
            <motion.i
              className="pi pi-refresh mr-8 cursor-pointer text-neutral-400"
              onClick={() => handleDownloadClients()}
              style={{ fontSize: '1.5rem' }}
              whileTap={{ scale: 0.9 }}
              initial={{ rotate: 0 }}
              animate={{ rotate: 450 }}
            />
            <Button
              className="p-button-info"
              label="Add a new client"
              icon="pi pi-user-plus"
              onClick={() => handleOpenModal()}
            />
          </div>
        </div>
        {clients.length === 0 ? (
          <motion.button
            type="button"
            whileTap={{ scale: 0.95 }}
            className="relative mt-8 block w-full rounded-lg border-2 border-dashed border-gray-300 p-12 text-center hover:border-gray-400  focus:border-primary-light focus:outline-none"
            onClick={() => handleOpenModal()}
          >
            <i
              className="pi pi-user-plus text-neutral-500"
              style={{ fontSize: '2em' }}
            />
            <span className="mt-2 block font-medium text-neutral-500">
              Add your first client
            </span>
          </motion.button>
        ) : (
          <motion.div
            className="mt-8"
            variants={fadeIn}
            initial="hidden"
            animate="visible"
          >
            <DataTable
              value={clients}
              responsiveLayout="scroll"
              stripedRows
              globalFilterFields={['firstName', 'lastName', 'externalClientId']}
              filters={filters}
              onRowSelect={onRowSelect}
              selectionMode="single"
              dataKey="id"
            >
              <Column field="firstName" header="First" sortable></Column>
              <Column field="lastName" header="Last" sortable></Column>
              <Column
                field="dateOfBirth"
                header="Date of Birth"
                sortable
              ></Column>
              <Column field="externalClientId" header="ID"></Column>
            </DataTable>
          </motion.div>
        )}

        <Dialog
          visible={showModal}
          onHide={() => setShowModal(false)}
          style={{ width: '600px' }}
          showHeader={false}
          className="rounded-lg"
        >
          <div className="px-6 py-4">
            <NewClientForm handleCloseModal={handleCloseModal} />
          </div>
        </Dialog>
      </div>
    </div>
  );
};

export default React.memo(ClientList);
