import { useAppDispatch } from '../store/store';
import useAuthenticatedRequest from '../hooks/useAuthenticatedRequest';
import * as actions from '../store/profile';
import { useAuth0 } from '@auth0/auth0-react';
import { Profile } from '../types';
import { format } from 'date-fns';

const useProfileService = () => {
  const { fetchRequest } = useAuthenticatedRequest();
  const dispatch = useAppDispatch();
  const baseUrl = process.env.REACT_APP_API_URL;
  const { user } = useAuth0();

  const getAllProfiles = async () => {
    if (!user) return;
    try {
      dispatch(actions.getAllProfiles());
      const profiles = await fetchRequest(
        `${baseUrl}/profile/get-all-profiles`,
        {
          method: 'GET',
        },
      );
      const formattedProfiles: Profile[] = profiles.map((profile: Profile) => ({
        ...profile,
        createdOn: format(new Date(profile.createdOn), 'MMM dd, yyyy'),
        lastLogin: format(new Date(profile.lastLogin), 'MMM dd, yyyy'),
      }));
      dispatch(actions.getAllProfilesSuccess(formattedProfiles));
    } catch (error) {
      dispatch(actions.getAllProfilesFailure(error));
    }
  };

  const getProfileByEmail = async (email: string) => {
    try {
      dispatch(actions.getProfileByEmail());
      const profile = await fetchRequest(`${baseUrl}/profile/get-by-email`, {
        method: 'GET',
        params: {
          email,
        },
      });
      dispatch(actions.getProfileByEmailSuccess(profile));
    } catch (error) {
      dispatch(actions.getProfileByEmailFailure(error));
      // handle error message
    }
  };

  const createProfile = async (data: any) => {
    if (!user) return;
    try {
      dispatch(actions.createProfile());
      const profile = await fetchRequest(`${baseUrl}/profile/create-profile`, {
        method: 'POST',
        body: {
          email: user.email,
          ...data,
        },
      });
      dispatch(actions.createProfileSuccess(profile));
    } catch (error) {
      console.error(error);
      dispatch(actions.createProfileFailure(error));
    }
  };

  const updateProfile = async (data: any) => {
    if (!user) return;
    try {
      dispatch(actions.updateProfile());
      const profile = await fetchRequest(`${baseUrl}/profile/update-profile`, {
        method: 'PUT',
        body: {
          email: user.email,
          ...data,
        },
      });
      dispatch(actions.updateProfileSuccess(profile));
    } catch (error) {
      console.error(error);
      dispatch(actions.updateProfileFailure(error));
    }
  };

  return {
    getAllProfiles,
    getProfileByEmail,
    createProfile,
    updateProfile,
  };
};

export default useProfileService;
