import React from 'react';

const BluetoothIcon = ({
  color,
  className,
}: {
  color: string;
  className: string;
}) => {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 511.928 511.928"
      width="25px"
      fill={color}
      className={className}
    >
      <path
        d="M391.599,387.235L268.292,258.702l123.2-123.2c4.16-4.16,4.16-10.88,0-15.04c-0.107-0.107-0.213-0.213-0.32-0.32
			l-128-117.333c-4.373-3.947-11.093-3.733-15.04,0.64c-1.813,1.92-2.773,4.587-2.773,7.253v224.107l-109.76-114.24
			c-4.053-4.267-10.88-4.373-15.04-0.32c-4.16,4.053-4.373,10.88-0.32,15.04l118.187,123.093l-118.08,117.973
			c-4.053,4.267-3.947,10.987,0.213,15.04c4.16,3.947,10.667,3.947,14.827,0l109.867-109.76v219.627
			c0,5.867,4.8,10.667,10.667,10.667c2.453,0,4.907-0.853,6.827-2.453l128-106.667c4.48-3.733,5.12-10.453,1.387-15.04
			C391.919,387.555,391.812,387.342,391.599,387.235z M266.586,34.808l101.867,93.44L266.586,230.222V34.808z M266.586,478.542
			V287.715l101.76,105.92L266.586,478.542z"
      />
    </svg>
  );
};

export default BluetoothIcon;
