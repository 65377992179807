export const fadeIn = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
  },
};

export const slideInFromLeft = {
  hidden: {
    x: -50,
  },
  visible: {
    x: 0,
    transition: {
      type: 'tween',
    },
  },
};

export const slideUpFromBottom = {
  hidden: {
    y: 25,
  },
  visible: {
    y: 0,
    transition: {
      type: 'spring',
    },
  },
};

export const slideUpFromBottomDelayed = {
  hidden: {
    opacity: 0,
    y: 10,
  },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      type: 'tween',
      delay: 1,
    },
  },
};

export const slideDownFromTop = {
  hidden: {
    opacity: 0,
    y: -25,
  },
  visible: {
    opacity: 1,
    y: 0,
  },
};

export const breathe = {
  initial: {
    scale: 1,
  },
  visible: {
    scale: 1.05,
    transition: {
      repeat: Infinity,
      duration: 2,
      delay: 3,
      repeatType: 'reverse',
    },
  },
};

export const fadeInDelayed = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
    transition: {
      delay: 0.8,
    },
  },
};

export const fadeInShortDelay = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
    transition: {
      delay: 0.3,
    },
  },
};

export const sidebarAnimation = {
  visible: {
    width: 220,
    transition: {
      when: 'beforeChildren',
      staggerChildren: 0.3,
      type: 'spring',
      mass: 1.3,
      stiffness: 200,
      damping: 20,
    },
  },
  hidden: {
    width: 70,
    transition: {
      when: 'afterChildren',
    },
  },
};
