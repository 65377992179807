import { parseISO, format } from 'date-fns';
import { Column } from 'primereact/column';
import { MultiSelect } from 'primereact/multiselect';
import { DataTable, DataTableSortOrderType } from 'primereact/datatable';
import { Paginator } from 'primereact/paginator';
import { useEffect, useState } from 'react';
import Button from '../../../../shared/components/Button';
import useWatchService from '../../../../shared/services/useWatchService';
import { useAppDispatch, useAppSelector } from '../../../../shared/store/store';
import {
  LoadingState,
  WatchData,
  WatchDataWithSettings,
} from '../../../../shared/types';
import FormInputTextarea from '../../../../shared/components/FormInputTextarea';
import { useForm } from 'react-hook-form';
import { InputTextarea } from 'primereact/inputtextarea';
import classNames from 'classnames';
import { setSingleWatchDataValues } from '../../../../shared/store/watch';
//TODO: Remove this date-fns, only for placeholder data
// import { addMinutes } from 'date-fns';
// import { FilterMatchMode, FilterOperator } from 'primereact/api';
// import TabSelect from '../../../components/TabSelect';
// import ChartDatePicker from '../../../components/ChartDatePicker';

export const WatchDataTable: React.FC = () => {
  //State
  const {
    watchData,
    watchDataLoading,
    watchDataCount,
    saveWatchDataLoading,
    watchEventNotesLoading,
  } = useAppSelector((state) => state.watch);
  const dispatch = useAppDispatch();
  const [resultsPerPage, setResultsPerPage] = useState(10);
  const [activePage, setActivePage] = useState<number>(0);
  const [searchQuery, setSearchQuery] = useState('');
  const [sortField, setSortField] = useState('date');
  const [sortOrder, setSortOrder] = useState<DataTableSortOrderType>(-1);
  const [activeEvent, setActiveEvent] = useState<WatchData | null>();
  const [expandedRows, setExpandedRows] = useState<any>(null);

  //Filters - not complete for this scope of work
  // const [dayViewActive, setDayViewActive] = useState(false);
  // const [weekViewActive, setWeekViewActive] = useState(true);
  // const [monthViewActive, setMonthViewActive] = useState(false);
  // const [date, setDate] = useState(new Date());

  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      notes: '',
    },
  });

  // Hooks
  const { getWatchData, updateWatchEventNotes } = useWatchService();

  // Effects
  useEffect(() => {
    handleWatchData();
  }, []);

  useEffect(() => {
    if (saveWatchDataLoading === LoadingState.LOADED) {
      handleWatchData();
    }
  }, [saveWatchDataLoading]);

  // Handlers
  const handleWatchData = () => {
    const field =
      sortField === 'date' || sortField === 'time' ? 'eventDate' : sortField;
    const order = sortOrder === 1 ? 'asc' : 'desc';
    getWatchData(resultsPerPage, activePage, searchQuery, field, order);
  };

  const sortChange = (event: {
    sortField: string;
    sortOrder: DataTableSortOrderType;
  }) => {
    const { sortField, sortOrder } = event;
    setSortField(sortField);
    setSortOrder(sortOrder);
    const field =
      sortField === 'date' || sortField === 'time' ? 'eventDate' : sortField;
    const order = sortOrder === 1 ? 'asc' : 'desc';
    getWatchData(resultsPerPage, activePage, searchQuery, field, order);
  };

  const pageChange = (event: {
    rows: number;
    page: number;
    first: number;
    pageCount: number;
  }) => {
    setActivePage(event.first);
    setResultsPerPage(event.rows);
    const field =
      sortField === 'date' || sortField === 'time' ? 'eventDate' : sortField;
    const order = sortOrder === 1 ? 'asc' : 'desc';
    getWatchData(event.rows, event.first, searchQuery, field, order);
  };

  const onRowExpand = (event: any) => {
    setActiveEvent(event.data);
  };

  const onRowCollapse = (event: any) => {
    setActiveEvent(null);
  };

  const handleSaveNote = async (data: any) => {
    //Pulling the event id from the row data
    updateWatchEventNotes(data.id, data.eventNotes);
  };

  //Methods for filtering. Not complete for this scope of work.
  // const handleSelectDayView = () => {
  //   setDayViewActive(true);
  //   setWeekViewActive(false);
  //   setMonthViewActive(false);
  // };

  // const handleSelectWeekView = () => {
  //   setWeekViewActive(true);
  //   setDayViewActive(false);
  //   setMonthViewActive(false);
  // };

  // const handleSelectMonthView = () => {
  //   setDayViewActive(false);
  //   setWeekViewActive(false);
  //   setMonthViewActive(true);
  // };

  // const handleSelectPrevious = () => {
  //   if (dayViewActive) {
  //     setDate(new Date(date.setDate(date.getDate() - 1)));
  //   }
  //   if (weekViewActive) {
  //     setDate(new Date(date.setDate(date.getDate() - 7)));
  //   }
  //   if (monthViewActive) {
  //     setDate(new Date(date.setMonth(date.getMonth() - 1)));
  //   }
  // };

  // const handleSelectNext = () => {
  //   if (dayViewActive) {
  //     setDate(new Date(date.setDate(date.getDate() + 1)));
  //   }
  //   if (weekViewActive) {
  //     setDate(new Date(date.setDate(date.getDate() + 7)));
  //   }
  //   if (monthViewActive) {
  //     setDate(new Date(date.setMonth(date.getMonth() + 1)));
  //   }
  // };

  //Templates
  const dateBodyTemplate = (rowData: WatchData) => {
    const date = parseISO(rowData.eventDate as any);
    return (
      <div className="flex items-center gap-x-4">
        {!rowData.viewed && (
          <span className=" relative flex h-3 w-3">
            <span className="absolute inline-flex h-full w-full rounded-full bg-tertiary-dark opacity-75 motion-safe:animate-ping"></span>
            <span className="inline-flex h-3 w-3 rounded-full bg-tertiary-light"></span>
          </span>
        )}
        <div className="relative flex flex-col">
          <span className="text-md font-bold">{format(date, 'eeee')}</span>
          <span className="text-tiny text-gray-500">
            {format(date, 'MM/dd/yyyy')}
          </span>
        </div>
      </div>
    );
  };

  const takenBodyTemplate = (rowData: WatchData) => {
    return (
      <div
        className={`flex items-center gap-x-2 rounded-lg ${
          rowData.actionTaken === 'yes'
            ? 'bg-green-200'
            : rowData.actionTaken === 'no'
            ? 'bg-red-200'
            : ''
        } py-1 px-4`}
      >
        {rowData.actionTaken === 'yes' ? (
          <i
            className="pi pi-check"
            style={{ fontSize: '1em', color: 'green' }}
          />
        ) : (
          rowData.actionTaken === 'no' && (
            <i
              className="pi pi-times"
              style={{ fontSize: '1em', color: 'red' }}
            />
          )
        )}
        <p
          className={`${
            rowData.actionTaken === 'yes'
              ? 'text-green-800'
              : rowData.actionTaken === 'no'
              ? 'text-red-800'
              : ''
          }
            text-tiny font-bold
        text-green-800`}
        >
          {rowData.actionTaken?.toUpperCase() || '--'}
        </p>
      </div>
    );
  };

  const repeatedBodyTemplate = (rowData: WatchData) => {
    return (
      <div
        className={`flex items-center gap-x-2 rounded-lg ${
          rowData.actionRepeated === 'yes'
            ? 'bg-green-200'
            : rowData.actionRepeated === 'no'
            ? 'bg-red-200'
            : ''
        } py-1 px-4`}
      >
        {rowData.actionRepeated === 'yes' ? (
          <i
            className="pi pi-check"
            style={{ fontSize: '1em', color: 'green' }}
          />
        ) : (
          rowData.actionRepeated === 'no' && (
            <i
              className="pi pi-times"
              style={{ fontSize: '1em', color: 'red' }}
            />
          )
        )}
        <p
          className={`${
            rowData.actionRepeated === 'yes'
              ? 'text-green-800'
              : rowData.actionRepeated === 'no'
              ? 'text-red-800'
              : ''
          }
            text-tiny font-bold
        text-green-800`}
        >
          {rowData.actionRepeated?.toUpperCase() || '--'}
        </p>
      </div>
    );
  };

  const timeBodyTemplate = (rowData: WatchData) => {
    const date = format(parseISO(rowData.eventDate as any), 'p');
    return (
      <div className="flex items-center gap-2">
        {rowData.eventNotes ? (
          <>
            <span>{date}</span>
            <i className="pi pi-file" style={{ fontSize: '0.75em' }} />
          </>
        ) : (
          <span>{date}</span>
        )}
      </div>
    );
  };

  const postActionBodyTemplate = (rowData: WatchData) => {
    return rowData.postActionHR || rowData.postActionHR === 0
      ? rowData.postActionHR
      : '--';
  };

  const triggerBodyTemplate = (rowData: WatchData) => {
    return rowData.triggerHR || rowData.triggerHR === 0
      ? rowData.triggerHR
      : '--';
  };

  const finalBodyTemplate = (rowData: WatchData) => {
    return rowData.finalHR || rowData.finalHR === 0 ? rowData.finalHR : '--';
  };

  const rowExpansionTemplate = (data: WatchDataWithSettings) => {
    const value = data.eventNotes || '';
    return (
      <div className=" ml-20 flex gap-x-10">
        <div className="flex flex-col gap-y-4">
          <p>
            <span className="font-bold">Upload Date: </span>
            {format(
              parseISO(data.updatedOn as unknown as string),
              'MM/dd/yyyy',
            )}
          </p>
          {data.settings && (
            <>
              <p>
                <span className="font-bold">Trigger set to: </span>
                {data.settings.triggerHr}
              </p>
              <p>
                <span className="font-bold">Number of cycles: </span>
                {data.settings.numberOfCycles}
              </p>
            </>
          )}
        </div>
        <div className="flex flex-col">
          <label htmlFor="notes-input" className="text-md mb-2 font-semibold">
            Notes
          </label>
          <div className="p-field">
            <InputTextarea
              id={data.id + '-input'}
              value={value}
              autoResize
              rows={3}
              cols={60}
              onChange={(e) => {
                /**
                 * updating notes via redux because this is the least complicated way to handle it
                 * Another option would be to keep the value of notes for each row in the state of
                 * the component, but that would mean keeping track of everything manually.  This
                 * doesn't make sense when we already have the values in state.
                 */
                dispatch(
                  setSingleWatchDataValues({
                    ...data,
                    eventNotes: e.target.value,
                  }),
                );
              }}
            />
          </div>
          <Button
            label="Save notes"
            onClick={() => handleSaveNote(data)}
            disabled={!data.eventNotes}
            loading={watchEventNotesLoading === LoadingState.LOADING}
          />
        </div>
      </div>
    );
  };

  return (
    <>
      {/* Filters - not complete for this scope of work. See top comment. */}
      {/* <div className="ml-12 mb-4 flex items-center gap-x-7">
        <p className="text-md font-semibold text-gray-600">Filter by date: </p>
        <ChartDatePicker
          handleSelectPrevious={handleSelectPrevious}
          handleSelectNext={handleSelectNext}
          date={date}
          setDate={setDate}
        />
        <TabSelect
          dayViewActive={dayViewActive}
          handleSelectDayView={handleSelectDayView}
          weekViewActive={weekViewActive}
          handleSelectWeekView={handleSelectWeekView}
          monthViewActive={monthViewActive}
          handleSelectMonthView={handleSelectMonthView}
        />
      </div> */}
      <DataTable
        value={watchData}
        emptyMessage={'No watch data available'}
        scrollable
        responsiveLayout="scroll"
        scrollHeight="flex"
        stripedRows
        sortField={sortField}
        sortOrder={sortOrder}
        onSort={(e) => sortChange(e as any)}
        expandedRows={expandedRows}
        onRowToggle={(e) => setExpandedRows(e.data)}
        onRowExpand={onRowExpand}
        onRowCollapse={onRowCollapse}
        rowExpansionTemplate={rowExpansionTemplate}
        // filters={filters}
        // filterDisplay="row"
        dataKey="id"
      >
        <Column expander style={{ flex: '0 0 5.1em' }} />
        <Column
          field="date"
          header="Event Date"
          body={dateBodyTemplate}
          sortable
        />
        <Column
          field="time"
          header="Event Time"
          sortable
          body={timeBodyTemplate}
        />
        <Column
          field="triggerHR"
          header="Trigger HR"
          sortable
          body={triggerBodyTemplate}
        />
        <Column
          field="actionTaken"
          header="Action Taken"
          sortable
          body={takenBodyTemplate}
          // filterField="actionTaken"
          // showFilterMatchModes={false}
          // filterMenuStyle={{ width: '16rem' }}
          // style={{ minWidth: '16rem' }}
          // filter
          // filterElement={actionTakenFilterTemplate}
          // showFilterMenu={false}
        />
        <Column
          field="postActionHR"
          header="Post Action HR"
          sortable
          body={postActionBodyTemplate}
        />
        <Column
          field="actionRepeated"
          header="Action Repeated"
          sortable
          body={repeatedBodyTemplate}
        />
        <Column
          field="finalHR"
          header="Final HR"
          sortable
          body={finalBodyTemplate}
        />
      </DataTable>
      <Paginator
        first={activePage}
        rows={resultsPerPage}
        totalRecords={watchDataCount}
        rowsPerPageOptions={[10, 20, 30]}
        onPageChange={(e) => {
          pageChange(e);
        }}
      ></Paginator>
    </>
  );
};

export default WatchDataTable;
