import { motion } from 'framer-motion';
import DashboardHeader from '../../components/DashboardHeader';
import { useAppDispatch, useAppSelector } from '../../../shared/store/store';
import { Divider } from 'primereact/divider';
import FormInput from '../../../shared/components/FormInput';
import FormSelect from '../../../shared/components/FormSelect';
import { useForm } from 'react-hook-form';
import Button from '../../../shared/components/Button';
import './WatchSettings.css';
import useBluetooth from '../../../shared/bluetooth-module/useBluetooth';
import { useEffect, useState } from 'react';
import { LoadingState } from '../../../shared/types';
import FullPageLoading from '../../../shared/components/FullPageLoading';
import useWatchSettingsService from '../../../shared/services/useWatchSettingsService';
import { showToast } from '../../../shared/store/alerts';

const WatchSettings = () => {
  const { client } = useAppSelector((state) => state.client);
  const { saveWatchSettings } = useBluetooth();
  const dispatch = useAppDispatch();
  const { getWatchSettings, createWatchSettings } = useWatchSettingsService();
  const [settingsSaving, setSettingsSaving] = useState(false);
  const { watchSettings, watchSettingsLoading } = useAppSelector(
    (state) => state.watch,
  );
  useEffect(() => {
    if (client) {
      getWatchSettings(client.id);
    }
  }, [JSON.stringify(client)]);

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
    getValues,
  } = useForm({
    mode: 'onTouched',
    defaultValues: {
      restingHr: 65,
      triggerHr: 90,
      // triggerExerciseAfter: 30,
      timeoutAfterNo: 5,
      timeoutAfterIgnore: 5,
      timeoutAfterSuccess: 5,
      numberOfCycles: 2,
    },
  });

  useEffect(() => {
    if (watchSettingsLoading === LoadingState.LOADED && watchSettings) {
      reset(watchSettings);
    }
  }, [watchSettingsLoading, JSON.stringify(watchSettings)]);

  const handleSaveSettings = async (data: any) => {
    if (!client) return;
    try {
      setSettingsSaving(true);
      await saveWatchSettings(data);
      // ensuring all values are number.  If changed in the UI they become a string.
      await createWatchSettings(
        {
          triggerHr: parseInt(data.triggerHr),
          restingHr: parseInt(data.restingHr),
          timeoutAfterNo: parseInt(data.timeoutAfterNo),
          timeoutAfterIgnore: parseInt(data.timeoutAfterIgnore),
          timeoutAfterSuccess: parseInt(data.timeoutAfterSuccess),
          numberOfCycles: parseInt(data.numberOfCycles),
        },
        client.id,
      );
      dispatch(
        showToast({
          severity: 'success',
          summary: 'Device settings saved!',
        }),
      );
    } finally {
      setSettingsSaving(false);
    }
  };
  const options = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
  return (
    <div className="w-full p-8">
      {client && (
        <>
          <DashboardHeader client={client} />
          {watchSettingsLoading === LoadingState.INIT ||
          watchSettingsLoading === LoadingState.LOADING ? (
            <>
              <FullPageLoading />
            </>
          ) : (
            <main className="mt-6 flex flex-col rounded-xl border border-gray-200 p-12">
              <section className="flex flex-col">
                <h2 className="text-xl font-bold">Heart Rate</h2>
                <Divider />
                <div className="flex items-center gap-x-24">
                  <div className="flex flex-col gap-y-4">
                    <label
                      htmlFor="restingHr-input"
                      className="text-lg text-gray-700"
                    >
                      Average heart rate
                    </label>
                    <div className="flex items-center gap-x-2">
                      <FormInput
                        name="restingHr"
                        control={control}
                        rules={{
                          required: {
                            value: true,
                            message: 'Required',
                          },
                        }}
                        errors={errors}
                        className="heart-rate-input"
                      />
                      <p className="ml-4 -mt-6 text-lg font-medium text-gray-600">
                        BPM
                      </p>
                    </div>
                  </div>
                  <div className="flex flex-col gap-y-4">
                    <label
                      htmlFor="triggerHr-input"
                      className="text-lg text-gray-700"
                    >
                      Trigger heart rate
                    </label>
                    <div className="flex items-center gap-x-2">
                      <FormInput
                        name="triggerHr"
                        control={control}
                        rules={{
                          required: {
                            value: true,
                            message: 'Required',
                          },
                        }}
                        errors={errors}
                        className="heart-rate-input"
                      />
                      <p className="ml-4 -mt-6 text-lg font-medium text-gray-600">
                        BPM
                      </p>
                    </div>
                  </div>
                  {/* <div className="flex flex-col gap-y-4">
                    <label
                      htmlFor="triggerExerciseAfter-input"
                      className="text-lg text-gray-700"
                    >
                      Average Heart Rate
                    </label>
                    <div className="flex items-center gap-x-2">
                      <FormInput
                        name="averageHr"
                        control={control}
                        rules={{
                          required: {
                            value: true,
                            message: 'Required',
                          },
                        }}
                        errors={errors}
                        className="heart-rate-input"
                      />
                      <p className="ml-4 -mt-6 text-lg font-medium text-gray-600">
                        Seconds
                      </p>
                    </div>
                  </div> */}
                  {/* This is in the designs but not defined */}
                  {/* <Button
                    label="Heart rate info"
                    className="p-button-info p-button-outlined h-max"
                    icon="pi pi-question-circle"
                  /> */}
                </div>
              </section>
              <section className="flex flex-col">
                <h2 className="text-xl font-bold">Device Timeout</h2>
                <Divider />
                <div className="flex items-center gap-x-24">
                  <div className="flex flex-col gap-y-4">
                    <label
                      htmlFor="timeoutAfterNo-input"
                      className="text-lg text-gray-700"
                    >
                      Length of timeout after "No"
                    </label>
                    <div className="flex items-center gap-x-2">
                      <FormInput
                        name="timeoutAfterNo"
                        control={control}
                        rules={{
                          required: {
                            value: true,
                            message: 'Required',
                          },
                        }}
                        errors={errors}
                        className="heart-rate-input"
                      />
                      <p className="ml-4 -mt-6 text-lg font-medium text-gray-600">
                        Seconds
                      </p>
                    </div>
                  </div>
                  <div className="flex flex-col gap-y-4">
                    <label
                      htmlFor="timeoutAfterIgnore-input"
                      className="text-lg text-gray-700"
                    >
                      Length of timeout after “Ignore”
                    </label>
                    <div className="flex items-center gap-x-2">
                      <FormInput
                        name="timeoutAfterIgnore"
                        control={control}
                        rules={{
                          required: {
                            value: true,
                            message: 'Required',
                          },
                        }}
                        errors={errors}
                        className="heart-rate-input"
                      />
                      <p className="ml-4 -mt-6 text-lg font-medium text-gray-600">
                        Seconds
                      </p>
                    </div>
                  </div>
                  <div className="flex flex-col gap-y-4">
                    <label
                      htmlFor="timeoutAfterSuccess-input"
                      className="text-lg text-gray-700"
                    >
                      Length of timeout after “Success”
                    </label>
                    <div className="flex items-center gap-x-2">
                      <FormInput
                        name="timeoutAfterSuccess"
                        control={control}
                        rules={{
                          required: {
                            value: true,
                            message: 'Required',
                          },
                        }}
                        errors={errors}
                        className="heart-rate-input"
                      />
                      <p className="ml-4 -mt-6 text-lg font-medium text-gray-600">
                        Seconds
                      </p>
                    </div>
                  </div>
                </div>
              </section>
              <section className="flex flex-col">
                <h2 className="text-xl font-bold">Breathing Exercises</h2>
                <Divider />
                <div className="flex items-center gap-x-24">
                  <div className="flex flex-col gap-y-4">
                    <label
                      htmlFor="numberOfCycles-input"
                      className="text-lg text-gray-700"
                    >
                      Number of cycles
                    </label>
                    <div className="flex items-center gap-x-2">
                      <FormSelect
                        name="numberOfCycles"
                        label="Number of cycles"
                        control={control}
                        errors={errors}
                        options={options}
                        rules={{
                          required: {
                            value: true,
                            message: 'Category is required',
                          },
                        }}
                      />
                      <p className="ml-4 -mt-6 text-lg font-medium text-gray-600">
                        Cycles
                      </p>
                    </div>
                  </div>
                </div>
              </section>
              <div>
                <Button
                  label="Save all settings"
                  loading={settingsSaving}
                  onClick={handleSubmit((data) => handleSaveSettings(data))}
                />
              </div>
            </main>
          )}
        </>
      )}
    </div>
  );
};

export default WatchSettings;
