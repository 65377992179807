import {
  NORDIC_SERVICE,
  DEVICES,
  NORDIC_TX,
  NORDIC_RX,
  DEFAULT_CHUNKSIZE,
} from './constants';
import {
  stringToArrayBuffer,
  atob,
  btoa,
  reformatCode,
  timeoutPromise,
} from './utils';

export class BluetoothConnection {
  private txCharacteristic: any;
  private rxCharacteristic: any;
  private txInProgress: boolean = false;
  private txDataQueue: any[] = [];
  private flowControlXOFF: boolean = false;
  private chunkSize: number = DEFAULT_CHUNKSIZE;

  btServer: any;
  btDevice: any;
  btReady: boolean = false;
  writeValue: any;
  receivedData: any = '';
  listening: boolean = false;
  writeCallback: any;
  prevString: string = '';

  static _instance: BluetoothConnection;

  constructor() {
    if (BluetoothConnection._instance) {
      return BluetoothConnection._instance;
    }
    BluetoothConnection._instance = this;
  }

  async connect(callback?: (ready: boolean, device: any) => void) {
    try {
      if (navigator.bluetooth.getDevices) {
        const pairedDevices = await navigator.bluetooth.getDevices();
        // console.log('paired devices', pairedDevices);
      }
      // console.log('Connecting to bluetooth...');
      const device = await navigator.bluetooth.requestDevice({
        filters: [{ namePrefix: DEVICES }, { services: [NORDIC_SERVICE] }],
        optionalServices: [NORDIC_SERVICE],
      });
      // console.log('Connected to device', device);
      device.addEventListener('gattserverdisconnected', () => {
        // console.log('BT> Disconnected (gattserverdisconnected)');
        this.closeConnection();
      });
      this.btDevice = device;
      await this.connectToDevice();

      // console.log('Bluetooth Ready!');
      this.btReady = true;
      if (callback) {
        callback(true, device);
      }
    } catch (error) {
      if (callback) {
        callback(false, undefined);
      }
    }
  }

  private async connectToDevice() {
    try {
      // connect to device if none is present
      if (!this.btDevice) {
        await this.connect();
      }
      if (!this.btDevice) {
        throw new Error('No device found');
      }
      const server = await this.btDevice.gatt.connect();
      // console.log('server connected...');
      this.btServer = server;
      const service = await server.getPrimaryService(NORDIC_SERVICE);
      // console.log('service connected');
      const tx = await service.getCharacteristic(NORDIC_TX);
      const rx = await service.getCharacteristic(NORDIC_RX);
      rx.addEventListener('characteristicvaluechanged', (event: any) => {
        // if we aren't listening, we don't care about the data
        if (!this.listening) return;
        // In Chrome 50+, a DataView is returned instead of an ArrayBuffer.
        const dataview = event.target.value;
        if (dataview.byteLength > this.chunkSize) {
          // console.log(
          //   'Received packet of length ' +
          //     dataview.byteLength +
          //     ', increasing chunk size',
          // );
          this.chunkSize = dataview.byteLength;
        }
        // handle flow control
        for (var i = 0; i < dataview.byteLength; i++) {
          var ch = dataview.getUint8(i);
          if (ch === 17) {
            // XON
            // console.log('XON received => resume upload');
            this.flowControlXOFF = false;
          }
          if (ch === 19) {
            // XOFF
            // console.log('XOFF received => pause upload');
            this.flowControlXOFF = true;
          }
          this.processData(dataview.buffer);
        }
      });
      await rx.startNotifications();
      this.txCharacteristic = tx;
      this.rxCharacteristic = rx;
      // give the device some time to catch up
      await timeoutPromise(1000);
      return;
    } catch (error) {
      console.error('error connecting bluetooth', error);
      throw error;
    }
  }

  async write(data: any, callback: any) {
    if (!this.btServer) {
      await this.connectToDevice();
    }

    // console.log('calling write', data);
    if (data) {
      const item = {
        type: 'write',
        data,
        callback,
        maxLength: data.length,
      };
      this.txDataQueue.push(item);
    }
    if (!this.btReady || this.txInProgress) return;
    this.writeChunk();
  }

  /**
   * Evaluates an expression on the device.
   * write -> listen -> process data -> callback
   */
  async evalExp(exp: string, callback: any) {
    try {
      if (!this.btServer) {
        await this.connectToDevice();
      }
      if (!this.btServer) {
        throw new Error('No device found');
      }
      await this.getPrompt();
      // pause before running the expression
      await timeoutPromise(250);

      // console.log('calling evalExp');
      const str = `\u0010print("<","<<");${exp};print(">>",">")\n`;
      // console.log('str', str);
      if (exp) {
        const item = {
          type: 'eval',
          data: str,
          callback,
          maxLength: str.length,
        };
        this.txDataQueue.push(item);
      }
      if (!this.btReady || this.txInProgress) return;
      this.writeChunk();
    } catch (error) {
      console.error('error evaling expression', error);
      throw error;
    }
  }

  /**
   * Writes to device
   * Called recursively until all data in queue is written
   *
   */
  private writeChunk() {
    // if flow control, try again later
    if (this.flowControlXOFF) {
      setTimeout(this.writeChunk, 100);
      return;
    }
    let chunk: any;
    // if we don't have a queue, exit
    if (!this.txDataQueue || !this.txDataQueue.length) return;
    // if we have a queue, get the first item
    const item = this.txDataQueue[0];
    if (item.data.length <= this.chunkSize) {
      chunk = item.data;
      item.data = undefined;
    } else {
      chunk = item.data.substr(0, this.chunkSize);
      item.data = item.data.substr(this.chunkSize);
    }
    this.txInProgress = true;
    this.txCharacteristic
      .writeValue(stringToArrayBuffer(chunk))
      .then(() => {
        // if we set data to undefined earlier, we're done
        if (!item.data) {
          this.txDataQueue.shift();
          this.listening = true;
          if (item.callback) this.writeCallback = item.callback;
        }
        this.txInProgress = false;
        // retrying until the queue is empty
        if (this.txDataQueue.length > 0) {
          this.writeChunk();
        }
      })
      .catch((error: any) => {
        // console.log('Error sending chunk', error);
        this.txInProgress = false;
        this.txDataQueue = [];
        this.closeConnection();
      });
  }

  closeConnection() {
    if (this.btServer) {
      this.btServer.disconnect();
      this.btServer = undefined;
      this.btReady = false;
      this.txCharacteristic = undefined;
      this.rxCharacteristic = undefined;
      this.chunkSize = DEFAULT_CHUNKSIZE; // set default
      this.btDevice = undefined;
    }
  }

  private processData(data: any) {
    // need this here because data sometimes is duplicated
    if (this.prevString === data) {
      return;
    }
    const bufView = new Uint8Array(data);
    for (let i = 0; i < bufView.length; i++) {
      this.receivedData += String.fromCharCode(bufView[i]);
    }
    this.prevString = data;
    // check if we got what we wanted
    const startProcess = this.receivedData.indexOf('< <<');
    const endProcess = this.receivedData.indexOf('>> >', startProcess);

    // processing the data
    if (startProcess >= 0 && endProcess > 0) {
      const result = this.receivedData.substring(startProcess + 4, endProcess);
      // console.log('Got ' + JSON.stringify(result));
      this.writeValue = result;
      // emit data and cleanup
      this.listening = false;
      this.receivedData = '';
      this.writeCallback(result);
      this.writeCallback = undefined;
    }
  }

  // could possible be named better/have a better implementation.  downloads file to blob and returns text.
  async downloadFile(fileName: string): Promise<string> {
    const CHUNKSIZE = 384; // or any multiple of 96 for atob/btoa
    return new Promise((resolve, reject) => {
      this.evalExp(
        `(function(filename) {var s = require("Storage").read(filename);if(s){ for (var i=0;i<s.length;i+=${CHUNKSIZE}) {console.log(btoa(s.substr(i,${CHUNKSIZE}))); }} else {var f=require("Storage").open(filename,"r");var d=f.read(${CHUNKSIZE});while (d!==undefined) {console.log(btoa(d));d=f.read(${CHUNKSIZE});}}})(${JSON.stringify(
          fileName,
        )});`,
        (file: any) => {
          // console.log('file received!');
          const data = atob(file);
          const rawdata = new Uint8Array(data.length);
          for (let i = 0; i < data.length; i++) rawdata[i] = data.charCodeAt(i);
          const fileBlob = new Blob([rawdata.buffer], { type: 'text/plain' });
          resolve(fileBlob.text());
        },
      ).catch((error: any) => {
        reject(error);
      });
    });
  }

  uploadFile(fileName: string, contents: string) {
    const upload = this.formatUploadString(fileName, contents);
    return new Promise((resolve, reject) => {
      this.evalExp(upload, () => {
        resolve(true);
      }).catch((error: any) => reject(error));
    });
  }

  formatUploadString(fileName: string, contents: string) {
    const CHUNKSIZE = 384; // or any multiple of 96 for atob/btoa
    const js = [];
    if ('string' != typeof contents)
      throw new Error('Expecting a string for contents');
    if (fileName.length == 0 || fileName.length > 28)
      throw new Error('Invalid filename length');
    var fn = JSON.stringify(fileName);
    for (var i = 0; i < contents.length; i += CHUNKSIZE) {
      var part = contents.substr(i, CHUNKSIZE);
      js.push(
        `require("Storage").write(${fn},atob(${JSON.stringify(
          btoa(part),
        )}),${i}${i == 0 ? ',' + contents.length : ''})`,
      );
    }
    const upload = '\x10' + js.join('\n').replace(/\n/g, '\n\x10');
    return upload;
  }

  async uploadCode(contents: string, retrying: boolean = false) {
    if (!retrying) await this.connectToDevice();
    await this.getPrompt();
    // format code to remove stuff the device won't like
    let code = reformatCode(contents) as string;
    // code = this.formatUploadString(fileName, contents);
    const time = new Date();
    // let code = '';
    code = `\x10setTime(${time.getTime() / 1000});E.setTimeZone(-${
      time.getTimezoneOffset() / 60
    })\n${code}`;
    // reset first
    this.write(`\x10reset();\n`, null);
    // adding timeout here so watch can catch up
    await timeoutPromise(250);
    // print statements signify beginning and end of write to processing.
    code = `\x10print();\n\x10print("<","<<");\n\x10${code}\n\x10print(">>",">");\n`;
    let timeout: any;
    let timeoutCount = 0;
    return new Promise((resolve, reject) => {
      this.listening = true;
      this.write(code, () => {
        if (timeout) clearTimeout(timeout);
        // console.log('write finished?');
        resolve(true);
      });
      timeout = setTimeout(() => {
        // console.log('error writing to device, retrying');
        if (timeoutCount < 3) {
          timeoutCount++;
          resolve(this.uploadCode(contents, true));
        } else {
          reject();
        }
      }, 30000);
    });
  }

  deleteFile(fileName: string) {
    const deleteExp = `require("Storage").erase(${JSON.stringify(fileName)})`;
    return new Promise((resolve) => {
      this.evalExp(deleteExp, () => resolve(true));
    });
  }

  getPrompt() {
    let timeout: any;
    return new Promise((resolve) => {
      this.write('\n\x10print("<","<<");\n\x10print(">>",">");\n', () => {
        // console.log('Prompt received, continuing!');
        if (timeout) {
          clearTimeout(timeout);
        }
        // console.log('resolving');
        resolve(true);
      });
      timeout = setTimeout(async () => {
        // console.log('No prompt received, resetting');
        this.write('\x03', null);
        // adding timeout here so watch can catch up
        await timeoutPromise(250);
        timeout = setTimeout(async () => {
          // console.log('Sending second reset');
          this.write('\x03', null);
          // adding timeout here so watch can catch up
          await timeoutPromise(250);
          resolve(this.getPrompt());
        }, 1000);
      }, 1000);
    });
  }
}
