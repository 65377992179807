import { motion } from 'framer-motion';
import { fadeIn } from '../../helpers/animation';

const LogoNoText: React.FC = () => {
  return (
    <motion.svg
      width="100%"
      viewBox="0 0 220 207"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      variants={fadeIn}
      initial="hidden"
      animate="visible"
    >
      <path
        d="M25.7059 75.6927H141.496C161.181 75.6927 177.158 58.7899 177.158 37.9695C177.158 17.1469 161.411 0 141.496 0C125.516 0 111.853 10.7787 107.222 26.9466C105.6 32.3358 108.612 37.9695 113.707 39.6851C118.801 41.4007 124.127 38.2156 125.749 32.8262C127.833 25.4769 134.317 20.5781 141.496 20.5781C150.527 20.5781 157.939 28.4177 157.939 37.9712C157.939 47.5247 150.527 55.3644 141.496 55.3644H25.7059C20.38 55.3644 15.9796 60.0191 15.9796 65.6528C16.2105 71.0384 20.38 75.6931 25.7059 75.6931V75.6927Z"
        fill="#79C8D1"
      />
      <path
        d="M101.432 130.074H25.7059C20.38 130.074 15.9796 134.729 15.9796 140.362C15.9796 145.996 20.38 150.651 25.7059 150.651H101.201C110.232 150.651 117.644 158.49 117.644 168.044C117.644 177.597 110.232 185.437 101.201 185.437C94.0222 185.437 87.538 180.292 85.4542 173.189C83.8324 167.8 78.5064 164.861 73.4117 166.33C68.3169 168.046 65.3056 173.679 66.9274 179.068C71.5586 195.236 85.2214 206.015 101.201 206.015C120.886 206.015 136.863 189.112 136.863 168.292C136.865 147.468 121.117 130.075 101.433 130.075L101.432 130.074Z"
        fill="#79C8D1"
      />
      <path
        d="M182.485 92.595H9.72633C4.40042 92.595 0 97.2497 0 102.883C0 108.517 4.40042 113.172 9.72633 113.172H182.485C192.442 113.172 200.778 121.746 200.778 132.523C200.778 143.055 192.672 151.874 182.485 151.874C174.611 151.874 167.433 146.24 165.116 138.156C163.494 132.767 158.168 129.582 153.074 131.298C147.979 133.013 144.967 138.647 146.589 144.036C151.221 160.693 166.042 172.206 182.485 172.206C203.095 172.206 220 154.324 220 132.522C220 110.477 203.095 92.595 182.485 92.595Z"
        fill="#79C8D1"
      />
    </motion.svg>
  );
};

export default LogoNoText;
