import React, { useState } from 'react';
import { Chart } from 'primereact/chart';
import { useReducedMotion } from 'framer-motion';

const DonutChart: React.FC<{ chartData: any }> = ({ chartData }) => {
  const reducedMotion = useReducedMotion();
  const [lightOptions] = useState({
    plugins: {
      legend: {
        labels: {
          color: '#495057',
        },
        position: 'right',
      },
    },
    layout: {
      autoPadding: false,
    },
    animation: {
      duration: reducedMotion ? 0 : 1000,
    },
  });
  return (
    <Chart
      type="doughnut"
      data={chartData}
      options={lightOptions}
      style={{ position: 'relative', height: '100px' }}
    />
  );
};

export default DonutChart;
