import useChartData from '../../../shared/hooks/useChartData';

const TotalTriggeredEvents = () => {
  const { firstWeekEvents, secondWeekEvents } = useChartData();

  const renderDifference = () => {
    if (firstWeekEvents && secondWeekEvents) {
      if (firstWeekEvents.count > secondWeekEvents.count) {
        return (
          <span>
            {firstWeekEvents.count - secondWeekEvents.count} more than last week
          </span>
        );
      }
      if (firstWeekEvents.count < secondWeekEvents.count) {
        return (
          <span>
            {secondWeekEvents.count - firstWeekEvents.count} less than last week
          </span>
        );
      }
      if (firstWeekEvents.count === secondWeekEvents.count) {
        return <span>The same as last week</span>;
      }
    }
  };

  return firstWeekEvents && secondWeekEvents ? (
    <div>
      <span className="text-3xl font-bold">{firstWeekEvents.count}</span>
      <p className="text-gray-400">Total triggered events this past week</p>
      <div
        className={`mt-5 flex items-center gap-x-2 font-bold ${
          firstWeekEvents.count < secondWeekEvents.count
            ? 'text-green-600'
            : firstWeekEvents.count === secondWeekEvents.count
            ? ''
            : 'text-red-500'
        }`}
      >
        {/* TODO: Make the icon conditional based on upward or downward trend */}
        <i
          className={`pi ${
            firstWeekEvents.count < secondWeekEvents.count
              ? 'pi-arrow-down'
              : firstWeekEvents.count === secondWeekEvents.count
              ? ''
              : 'pi-arrow-up'
          }`}
        />
        <p>
          {renderDifference()} ({secondWeekEvents.count})
        </p>
      </div>
    </div>
  ) : (
    <></>
  );
};

export default TotalTriggeredEvents;
