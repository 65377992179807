const TabSelect = ({
  dayViewActive,
  handleSelectDayView,
  weekViewActive,
  handleSelectWeekView,
  monthViewActive,
  handleSelectMonthView,
}: {
  dayViewActive: boolean;
  handleSelectDayView: () => void;
  weekViewActive: boolean;
  handleSelectWeekView: () => void;
  monthViewActive: boolean;
  handleSelectMonthView: () => void;
}) => {
  return (
    <div className="mr-12 flex cursor-pointer items-center gap-x-8 text-gray-400">
      <p
        className={dayViewActive ? 'font-bold text-primary-light' : ''}
        onClick={() => handleSelectDayView()}
      >
        1 day
      </p>
      <p
        className={weekViewActive ? 'font-bold text-primary-light' : ''}
        onClick={() => handleSelectWeekView()}
      >
        7 days
      </p>
      <p
        className={monthViewActive ? 'font-bold text-primary-light' : ''}
        onClick={() => handleSelectMonthView()}
      >
        4 weeks
      </p>
    </div>
  );
};

export default TabSelect;
