import { format } from 'date-fns';
import useChartData from '../../../shared/hooks/useChartData';

const MostEvents = () => {
  const { firstWeekDay, secondWeekDay } = useChartData();

  return (
    <div>
      <>
        <span className="text-3xl font-bold">
          {firstWeekDay
            ? format(firstWeekDay.day, 'EEEE')
            : 'No data available'}
        </span>
        <p className="text-gray-400">When the most events occurred this week</p>
      </>
      {secondWeekDay && (
        <div className="mt-5 flex items-center gap-x-2">
          <>
            <p>
              Last week’s day with the most events was{' '}
              <span className="font-bold">
                {format(secondWeekDay.day, 'EEEE')}
              </span>
            </p>
          </>
        </div>
      )}
    </div>
  );
};

export default MostEvents;
