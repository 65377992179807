import { useAppDispatch } from '../store/store';
import useAuthenticatedRequest from '../hooks/useAuthenticatedRequest';
import { useAuth0 } from '@auth0/auth0-react';
import * as actions from '../store/faq';
import { FrequentlyAskedQuestion } from '../types';
import sanitizeHtml from 'sanitize-html';

const useFaqService = () => {
  const { fetchRequest } = useAuthenticatedRequest();
  const dispatch = useAppDispatch();
  const baseUrl = process.env.REACT_APP_API_URL;
  const { user } = useAuth0();

  const getAllFaqs = async () => {
    if (!user) return;
    try {
      dispatch(actions.getAllFaqs());
      const faqs = await fetchRequest(`${baseUrl}/faq/get-all-faqs`, {
        method: 'GET',
      });
      dispatch(actions.getAllFaqsSuccess(faqs));
    } catch (error) {
      dispatch(actions.getAllFaqsFailure(error));
    }
  };

  const createFaq = async (data: FrequentlyAskedQuestion) => {
    if (!user) return;
    try {
      dispatch(actions.createFaq());
      // The answer is going to be a string of Rich Text HTML, so we want to sanitize it.
      const sanitizedAnswerHtml = sanitizeHtml(data.answer);
      const faq = await fetchRequest(`${baseUrl}/faq/create-faq`, {
        method: 'POST',
        body: {
          question: data.question,
          answer: sanitizedAnswerHtml,
        },
      });
      dispatch(actions.createFaqSuccess(faq));
    } catch (error) {
      dispatch(actions.createFaqFailure(error));
    }
  };

  const updateFaq = async (data: Partial<FrequentlyAskedQuestion>) => {
    if (!user) return;
    try {
      dispatch(actions.updateFaq());
      const faq = await fetchRequest(`${baseUrl}/faq/update-faq`, {
        method: 'PUT',
        body: {
          ...data,
        },
      });
      dispatch(actions.updateFaqSuccess(faq));
    } catch (error) {
      dispatch(actions.updateFaqFailure(error));
    }
  };

  const deleteFaq = async (id: number) => {
    if (!user) return;
    try {
      dispatch(actions.deleteFaq());
      const faq = await fetchRequest(`${baseUrl}/faq/delete-faq`, {
        method: 'DELETE',
        body: {
          id,
        },
      });
      dispatch(actions.deleteFaqSuccess(faq));
    } catch (error) {
      dispatch(actions.deleteFaqFailure(error));
    }
  };

  return {
    createFaq,
    getAllFaqs,
    updateFaq,
    deleteFaq,
  };
};

export default useFaqService;
