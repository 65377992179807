import { useEffect, useState, useRef } from 'react';
import { Chart } from 'primereact/chart';
import {
  format,
  addDays,
  startOfDay,
  endOfDay,
  parseISO,
  eachDayOfInterval,
  isSameDay,
  eachMinuteOfInterval,
} from 'date-fns';
import { ChartData } from 'chart.js';
import { useAppSelector } from '../../../shared/store/store';
import { useReducedMotion } from 'framer-motion';
import { WatchData } from '../../../shared/types';
import { isSameMinute } from 'date-fns/esm';

/**
 * Line Chart
 *
 * Populating data for the chart based on the averages over the specified interval
 * ex. if the interval is 1 day, the data will be populated with averages for each hour of the day
 *
 */
const LineChart = ({
  date,
  dayViewActive,
  weekViewActive,
  monthViewActive,
}: {
  date: Date;
  dayViewActive?: boolean;
  weekViewActive?: boolean;
  monthViewActive?: boolean;
}) => {
  //Hooks
  const reducedMotion = useReducedMotion();
  const { chartData } = useAppSelector((state) => state.watch);

  const dayLabelsAndData = () => {
    const minuteArray = eachMinuteOfInterval({
      start: startOfDay(date),
      end: endOfDay(date),
    });
    const triggerData = [];
    const postActionData = [];
    const minuteLabels = [];
    for (let i = 0; i < minuteArray.length; i++) {
      const minute = minuteArray[i];
      const minuteAverage = chartData.reduce(
        (acc: any, el: WatchData) => {
          if (isSameMinute(parseISO(el.eventDate as any), minute)) {
            return {
              triggerHR: (acc.triggerHR + el.triggerHR) / 2,
              postActionHR: el.postActionHR
                ? (acc.postActionHR + el.postActionHR) / 2
                : acc.postActionHR,
            };
          } else {
            return acc;
          }
        },
        { triggerHR: 0, postActionHR: 0 },
      );
      triggerData.push(minuteAverage.triggerHR || null);
      postActionData.push(minuteAverage.postActionHR || null);
      const label = format(minute, 'H:mm');
      minuteLabels.push(label);
    }
    return {
      minuteLabels,
      postActionData,
      triggerData,
    };
  };

  const weekLabelsAndData = () => {
    const dayArray = eachDayOfInterval({
      start: startOfDay(addDays(date, -7)),
      end: endOfDay(date),
    });
    const triggerData = [];
    const postActionData = [];
    const dayLabels = [];
    for (let i = 0; i < dayArray.length; i++) {
      const day = dayArray[i];
      const dayAverage = chartData.reduce(
        (acc, el) => {
          if (isSameDay(parseISO(el.eventDate as any), day)) {
            return {
              triggerHR: (acc.triggerHR + el.triggerHR) / 2,
              postActionHR: el.postActionHR
                ? (acc.postActionHR + el.postActionHR) / 2
                : acc.postActionHR,
            };
          } else {
            return acc;
          }
        },
        { triggerHR: 0, postActionHR: 0 },
      );
      triggerData.push(dayAverage.triggerHR || null);
      postActionData.push(dayAverage.postActionHR || null);
      const label = format(day, 'M/dd/yy');
      dayLabels.push(label);
    }
    return {
      dayLabels,
      postActionData,
      triggerData,
    };
  };

  const monthLabelsAndData = () => {
    const dayArray = eachDayOfInterval({
      start: startOfDay(addDays(date, -28)),
      end: endOfDay(date),
    });
    const triggerData = [];
    const postActionData = [];
    const dayLabels = [];
    for (let i = 0; i < dayArray.length; i++) {
      const day = dayArray[i];
      const dayAverage = chartData.reduce(
        (acc, el) => {
          if (isSameDay(parseISO(el.eventDate as any), day)) {
            return {
              triggerHR: (acc.triggerHR + el.triggerHR) / 2,
              postActionHR: el.postActionHR
                ? (acc.postActionHR + el.postActionHR) / 2
                : acc.postActionHR,
            };
          } else {
            return acc;
          }
        },
        { triggerHR: 0, postActionHR: 0 },
      );
      triggerData.push(dayAverage.triggerHR || null);
      postActionData.push(dayAverage.postActionHR || null);
      const label = format(day, 'M/dd/yy');
      dayLabels.push(label);
    }
    return {
      dayLabels,
      postActionData,
      triggerData,
    };
  };

  const triggerDefaults = {
    label: 'Trigger BPM',
    fill: true,
    backgroundColor: 'rgba(255, 167, 38, 0.15)',
    borderColor: '#FFA726',
    tension: 0.4,
    spanGaps: true,
  };

  const postActionDefaults = {
    label: 'Final BPM',
    fill: true,
    backgroundColor: 'rgba(0, 188, 212, 0.15)',
    borderColor: '#00BCD4',
    tension: 0.4,
    spanGaps: true,
  };

  //State
  const [data, setData] = useState<ChartData<'line'> | null>(null);

  //Effects
  useEffect(() => {
    if (dayViewActive) {
      const { minuteLabels, postActionData, triggerData } = dayLabelsAndData();
      setData({
        labels: minuteLabels,
        datasets: [
          { ...triggerDefaults, data: triggerData || [] },
          { ...postActionDefaults, data: postActionData || [] },
        ],
      });
    } else if (weekViewActive) {
      const { dayLabels, postActionData, triggerData } = weekLabelsAndData();
      setData({
        labels: dayLabels,
        datasets: [
          { ...triggerDefaults, data: triggerData || [] },
          { ...postActionDefaults, data: postActionData || [] },
        ],
      });
    } else if (monthViewActive) {
      const { dayLabels, postActionData, triggerData } = monthLabelsAndData();
      setData({
        labels: dayLabels,
        datasets: [
          { ...triggerDefaults, data: triggerData || [] },
          { ...postActionDefaults, data: postActionData || [] },
        ],
      });
    }
  }, [
    date,
    dayViewActive,
    weekViewActive,
    monthViewActive,
    JSON.stringify(chartData),
  ]);

  const getLightTheme = () => {
    let basicOptions = {
      animation: !reducedMotion,
      maintainAspectRatio: false,
      showLine: false,
      aspectRatio: 0.6,
      pointDotStrokeWidth: 4,
      plugins: {
        legend: {
          labels: {
            color: '#495057',
          },
          position: 'bottom',
        },
      },
      scales: {
        x: {
          ticks: {
            color: '#495057',
          },
          grid: {
            color: '#ebedef',
          },
        },
        y: {
          ticks: {
            color: '#495057',
          },
          grid: {
            color: '#ebedef',
          },
        },
      },
    };
    return basicOptions;
  };

  const basicOptions = getLightTheme();

  return (
    data && (
      <Chart
        type="line"
        data={data}
        options={basicOptions}
        className="my-4 w-full"
      />
    )
  );
};

export default LineChart;
