import { useReducedMotion } from 'framer-motion';
import { Skeleton } from 'primereact/skeleton';
import { useEffect, useState } from 'react';
import useChartData from '../../../shared/hooks/useChartData';
import DonutChart from '../DonutChart';

const ActivityResponses = () => {
  const { activityResponses } = useChartData();
  const [chartData, setChartData] = useState<any>();
  const reducedMotion = useReducedMotion();

  useEffect(() => {
    if (!activityResponses) return;
    setChartData({
      labels: [
        `Yes: ${Math.round(activityResponses.yes)}%`,
        `No: ${Math.round(activityResponses.no)}%`,
        `Ignore: ${Math.round(activityResponses.ignore)}%`,
      ],
      datasets: [
        {
          data: [
            activityResponses.yes,
            activityResponses.no,
            activityResponses.ignore,
          ],
          backgroundColor: ['#2C9EE6', '#6DCEED', '#497BDD'],
          hoverBackgroundColor: ['#2C9EE6', '#6DCEED', '#497BDD'],
        },
      ],
    });
  }, [activityResponses]);

  return (
    <div>
      <h2 className="text-xl font-medium">Activity Response</h2>
      {activityResponses ? (
        <div className="-mt-6 flex w-full justify-center">
          {chartData && <DonutChart chartData={chartData} />}
        </div>
      ) : (
        <div>
          <p className="text-gray-500">No data available</p>
          <Skeleton
            shape="circle"
            size="180px"
            className="my-4 ml-16"
            animation={reducedMotion ? 'none' : 'wave'}
          />
        </div>
      )}
    </div>
  );
};

export default ActivityResponses;
