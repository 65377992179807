import { useEffect } from 'react';
import { RouteProps, Navigate, useLocation } from 'react-router-dom';
import useProfileService from '../../services/useProfileService';
import { useAppSelector } from '../../store/store';
import { LoadingState } from '../../types';
import FullPageLoading from '../FullPageLoading';

const ProfileGuard: React.FC<RouteProps> = ({ children }) => {
  const { userProfile, profileLoading } = useAppSelector(
    (state) => state.profile,
  );
  const location = useLocation();
  const { updateProfile } = useProfileService();

  useEffect(() => {
    if (profileLoading === LoadingState.LOADED && userProfile) {
      // handle last login here.
      updateProfile({ lastLogin: new Date() });
    }
  }, [profileLoading]);

  // possible "error" condition here that isn't being addressed
  return profileLoading === LoadingState.LOADING ||
    profileLoading === LoadingState.INIT ? (
    <FullPageLoading />
  ) : profileLoading === LoadingState.LOADED && userProfile ? (
    // just in case user goes to register directly
    location.pathname.includes('register') ? (
      <Navigate to="/" replace />
    ) : (
      <>{children}</>
    )
  ) : location.pathname.includes('register') ? (
    <>{children}</>
  ) : (
    <Navigate to="/register" replace />
  );
};

export default ProfileGuard;
