import { createSlice } from '@reduxjs/toolkit';

enum AlertTypeEnum {
  Success = 'success',
  Info = 'info',
  Warn = 'warn',
  Error = 'error',
}

interface AppAlert {
  severity: AlertTypeEnum;
  summary: string;
  detail: string;
  duration?: number;
}

export interface AlertsState {
  toasts: AppAlert[];
}

export const alertsSlice = createSlice({
  name: 'alerts',
  initialState: {
    toasts: [],
  } as AlertsState,
  reducers: {
    showToast: (state, { payload }) => {
      state.toasts.push(payload);
    },
    removeToast: (state) => {
      state.toasts.shift();
    },
  },
});

export const { showToast, removeToast } = alertsSlice.actions;

export default alertsSlice.reducer;
