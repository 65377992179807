import { useReducedMotion } from 'framer-motion';
import { Skeleton } from 'primereact/skeleton';
import { useState, useEffect } from 'react';
import useChartData from '../../../shared/hooks/useChartData';
import DonutChart from '../DonutChart';

const BPMFeedback = () => {
  const { deviceBPMFeedback } = useChartData();
  const [chartData, setChartData] = useState<any>();
  const reducedMotion = useReducedMotion();
  useEffect(() => {
    if (!deviceBPMFeedback) return;
    setChartData({
      labels: [
        `Success: ${Math.round(deviceBPMFeedback.success)}%`,
        `Do-Over: ${Math.round(deviceBPMFeedback.doOver)}%`,
      ],
      datasets: [
        {
          data: [deviceBPMFeedback.success, deviceBPMFeedback.doOver],
          backgroundColor: ['#71C47F', '#FFD748'],
          hoverBackgroundColor: ['#71C47F', '#FFD748'],
        },
      ],
    });
  }, [deviceBPMFeedback]);
  return (
    <div>
      <h2 className="text-xl font-medium">Device BPM Feedback</h2>
      {deviceBPMFeedback ? (
        <div className="-mt-6 flex w-full justify-center">
          {chartData && <DonutChart chartData={chartData} />}
        </div>
      ) : (
        <div>
          <p className="text-gray-500">No data available</p>
          <Skeleton
            shape="circle"
            size="180px"
            className="my-4 ml-16"
            animation={reducedMotion ? 'none' : 'wave'}
          />
        </div>
      )}
    </div>
  );
};

export default BPMFeedback;
