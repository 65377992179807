import { ChevronRightIcon, ChevronLeftIcon } from '@heroicons/react/solid';
import { motion } from 'framer-motion';
import { Calendar } from 'primereact/calendar';
import './ChartDatePicker.css';
export default function ChartDatePicker({
  handleSelectPrevious,
  handleSelectNext,
  date,
  setDate,
}: {
  handleSelectPrevious: () => void;
  handleSelectNext: () => void;
  date: any;
  setDate: (date: any) => void;
}) {
  return (
    <>
      <motion.div
        className="flex max-h-fit items-center rounded-md shadow-sm md:items-stretch"
        whileTap={{ scale: 0.95 }}
        layout
      >
        <button
          type="button"
          className="flex items-center justify-center rounded-l-md border border-r-0 border-gray-300 bg-white py-2 pl-3 pr-4 text-gray-400 hover:text-gray-500 focus:relative md:w-9 md:px-2 md:hover:bg-gray-100"
          onClick={() => handleSelectPrevious()}
        >
          <span className="sr-only">Previous</span>
          <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
        </button>
        <div className="relative border-t border-b border-gray-300 bg-white px-3.5 text-sm font-medium text-gray-700 hover:text-gray-900 focus:relative md:block">
          <Calendar
            value={date}
            onChange={(e) => setDate(e.value)}
            dateFormat="M d"
            className="chart-calendar"
          ></Calendar>
          <i className="pi pi-calendar pointer-events-none absolute left-6 top-3.5" />
        </div>
        <span className="relative -mx-px h-5 w-px bg-gray-300 md:hidden" />
        <button
          type="button"
          className="flex items-center justify-center rounded-r-md border border-l-0 border-gray-300 bg-white py-2 pl-4 pr-3 text-gray-400 hover:text-gray-500 focus:relative md:w-9 md:px-2 md:hover:bg-gray-100"
          onClick={() => handleSelectNext()}
        >
          <span className="sr-only">Next</span>
          <ChevronRightIcon
            className="h-5 w-5"
            aria-hidden="true"
            onClick={setDate}
          />
        </button>
      </motion.div>
    </>
  );
}
