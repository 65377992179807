import { ErrorMessage } from '@hookform/error-message';
import { Controller } from 'react-hook-form';
import { useEffect, useState } from 'react';
import { Dropdown } from 'primereact/dropdown';
import classNames from 'classnames';

const FormDropdown = ({
  control,
  errors,
  name,
  label,
  rules,
  inputRef,
  placeholder,
  options,
  showClear,
}: {
  control: any;
  errors: any;
  name: string;
  label: string;
  options: any;
  rules?: any;
  inputRef?: any;
  placeholder?: string;
  showClear?: boolean;
}) => {
  const [loaded, setLoaded] = useState(false);
  useEffect(() => {
    setLoaded(true);
  }, []);

  return (
    <div className="p-field">
      <span className="p-float-label">
        <Controller
          name={name}
          control={control}
          rules={rules}
          render={({ field, fieldState }) => {
            return (
              <Dropdown
                id={name + '-input'}
                className={classNames('w-full', {
                  'p-invalid': fieldState.invalid,
                })}
                {...field}
                ref={inputRef}
                options={options}
                placeholder={placeholder}
                showClear={showClear}
              />
            );
          }}
        />
        <label htmlFor={name + '-input'}>{label}</label>
      </span>

      <div className="mx-2 mb-4 flex h-4 w-full items-baseline justify-end pr-4">
        <ErrorMessage
          errors={errors}
          name={name}
          render={({ message }) => (
            <small className="p-error p-d-block">{message}</small>
          )}
        />
      </div>
    </div>
  );
};
export default FormDropdown;
