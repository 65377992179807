import React, { useEffect, useState } from 'react';
import { motion, useReducedMotion } from 'framer-motion';
import { slideDownFromTop } from '../../../shared/helpers/animation';
import { useAppSelector } from '../../../shared/store/store';
import Button from '../../../shared/components/Button';
import LineChart from '../../components/LineChart';
import DonutChart from '../../components/DonutChart';
import TabSelect from '../../components/TabSelect';
import ChartDatePicker from './../../components/ChartDatePicker';
import useWatchService from '../../../shared/services/useWatchService';
import { addWeeks, endOfDay, parseISO, startOfDay } from 'date-fns';
import TotalTriggeredEvents from '../../components/TotalTriggeredEvents';
import useChartData from '../../../shared/hooks/useChartData';
import MostEvents from '../../components/MostEvents';
import ActivityResponses from '../../components/ActivityResponses';
import BPMFeedback from '../../components/BPMFeedback';
import BPMChange from '../../components/BPMChange';
import DashboardHeader from '../../components/DashboardHeader';
import { addMinutes, formatDistanceToNow } from 'date-fns';
import { Skeleton } from 'primereact/skeleton';

const Charts: React.FC = () => {
  const { client } = useAppSelector((state) => state.client);
  const [activeView, setActiveView] = useState<'week' | 'day' | 'month'>(
    'week',
  );
  const { chartData } = useAppSelector((state) => state.watch);
  const [date, setDate] = useState(new Date());
  const { getChartData } = useWatchService();
  const [lastUpdated, setLastUpdated] = useState<Date | null>(null);
  const [dataAvailable, setDataAvailable] = useState(false);

  const reducedMotion = useReducedMotion();
  // triggers fetch for data for previous week charts
  useChartData();

  const handleSelectPrevious = () => {
    if (activeView === 'day') {
      setDate(new Date(date.setDate(date.getDate() - 1)));
    }
    if (activeView === 'week') {
      setDate(new Date(date.setDate(date.getDate() - 7)));
    }
    if (activeView === 'month') {
      setDate(new Date(date.setMonth(date.getMonth() - 1)));
    }
  };

  const handleSelectNext = () => {
    if (activeView === 'day') {
      setDate(new Date(date.setDate(date.getDate() + 1)));
    }
    if (activeView === 'week') {
      setDate(new Date(date.setDate(date.getDate() + 7)));
    }
    if (activeView === 'month') {
      setDate(new Date(date.setMonth(date.getMonth() + 1)));
    }
  };

  useEffect(() => {
    if (!client) return;
    let start;
    const end = endOfDay(date);
    if (activeView === 'day') {
      start = startOfDay(date);
    }
    if (activeView === 'week') {
      start = startOfDay(addWeeks(date, -1));
    }
    if (activeView === 'month') {
      start = startOfDay(addWeeks(date, -4));
    }
    if (start) {
      getChartData(start, end);
    }
  }, [date, client, activeView]);

  useEffect(() => {
    if (chartData) {
    }
  }, [JSON.stringify(chartData)]);

  //TODO: Get last updated info from db
  useEffect(() => {
    if (client && client.watch) {
      setLastUpdated(parseISO(client.watch.updatedOn as unknown as string));
    }
  }, [JSON.stringify(client)]);

  return (
    <div className="w-full px-8 py-6">
      {client && (
        <>
          <DashboardHeader client={client} />

          <main className="mt-6 grid grid-cols-3 grid-rows-[1fr_1fr_min-content] gap-x-4 gap-y-4">
            <motion.div className="col-span-2 row-span-2 flex flex-col justify-center rounded-md border border-gray-200 bg-white px-8">
              <div className="flex items-center justify-between">
                <div className="my-6 flex flex-col">
                  <h2 className=" text-xl font-medium ">Heart Rate Levels</h2>
                  <div className="mt-2 flex items-center gap-x-2">
                    <span className=" relative flex h-3 w-3">
                      <span className="absolute inline-flex h-full w-full rounded-full bg-cyan-600 opacity-75 motion-safe:animate-ping"></span>
                      <span className="inline-flex h-3 w-3 rounded-full bg-cyan-700"></span>
                    </span>
                    {lastUpdated && (
                      <p className="text-gray-500">
                        Last updated:{' '}
                        {formatDistanceToNow(lastUpdated, { addSuffix: true })}
                      </p>
                    )}
                  </div>
                </div>
                <div className="flex">
                  <TabSelect
                    dayViewActive={activeView === 'day'}
                    handleSelectDayView={() => setActiveView('day')}
                    weekViewActive={activeView === 'week'}
                    handleSelectWeekView={() => setActiveView('week')}
                    monthViewActive={activeView === 'month'}
                    handleSelectMonthView={() => setActiveView('month')}
                  />
                  <ChartDatePicker
                    handleSelectPrevious={handleSelectPrevious}
                    handleSelectNext={handleSelectNext}
                    date={date}
                    setDate={setDate}
                  />
                </div>
              </div>
              <LineChart
                date={date}
                dayViewActive={activeView === 'day'}
                weekViewActive={activeView === 'week'}
                monthViewActive={activeView === 'month'}
              />
            </motion.div>
            <motion.div
              className="flex flex-col rounded-md border border-gray-200 px-8 pt-6 shadow-sm transition-shadow hover:shadow-lg"
              whileHover={{ scale: 1.04 }}
            >
              <ActivityResponses />
            </motion.div>
            <motion.div
              className="flex flex-col rounded-md border border-gray-200 px-8 pt-6 shadow-sm transition-shadow hover:shadow-lg"
              whileHover={{ scale: 1.04 }}
            >
              <BPMFeedback />
            </motion.div>
            <motion.div
              className="flex max-h-max flex-col rounded-md border border-gray-200 p-6 shadow-sm transition-shadow hover:shadow-lg"
              whileHover={{ scale: 1.04 }}
            >
              <TotalTriggeredEvents />
            </motion.div>
            <motion.div
              className="flex max-h-max flex-col rounded-md border border-gray-200 p-6 shadow-sm transition-shadow hover:shadow-lg"
              whileHover={{ scale: 1.04 }}
            >
              <MostEvents />
            </motion.div>
            <motion.div
              className="flex max-h-max flex-col rounded-md border border-gray-200 p-6 shadow-sm transition-shadow hover:shadow-lg"
              whileHover={{ scale: 1.04 }}
            >
              <BPMChange />
            </motion.div>
          </main>
        </>
      )}
    </div>
  );
};

export default Charts;
