import {
  addDays,
  addWeeks,
  eachDayOfInterval,
  endOfDay,
  isSameDay,
  parseISO,
  startOfDay,
} from 'date-fns';
import { useEffect, useState } from 'react';
import useWatchService from '../services/useWatchService';
import { useAppSelector } from '../store/store';
import { WatchData } from '../types';

const useChartData = () => {
  const { getWatchDataRange } = useWatchService();
  const { client } = useAppSelector((state) => state.client);
  const [firstWeekEvents, setFirstWeekEvents] = useState<
    { data: WatchData[]; count: number } | undefined
  >();
  const [secondWeekEvents, setSecondWeekEvents] = useState<
    { data: WatchData[]; count: number } | undefined
  >();
  const [firstWeekDay, setFirstWeekDay] = useState<
    { day: Date; events: WatchData[] } | undefined
  >();
  const [secondWeekDay, setSecondWeekDay] = useState<
    { day: Date; events: WatchData[] } | undefined
  >();
  const [activityResponses, setActivityResponses] = useState<{
    yes: number;
    no: number;
    ignore: number;
  }>();
  const [deviceBPMFeedback, setDeviceBPMFeedback] = useState<{
    success: number;
    doOver: number;
  }>();
  const [firstBPMChange, setFirstBPMChange] = useState<number>();
  const [secondBPMChange, setSecondBPMChange] = useState<number>();

  useEffect(() => {
    if (client) {
      fetchData();
    }
  }, [JSON.stringify(client)]);

  useEffect(() => {
    if (firstWeekEvents && secondWeekEvents) {
      daysWithMostEventsData();
      calculateActivityResponses();
      calculateBPMFeedback();
      calculateBPMChange();
    }
  }, [firstWeekEvents, secondWeekEvents]);

  const fetchData = async () => {
    const today = new Date();
    const firstWeek = addWeeks(today, -1);
    const secondWeek = addWeeks(today, -2);
    const [firstWeekData, secondWeekData] = await Promise.all([
      getWatchDataRange(startOfDay(firstWeek), endOfDay(today)),
      getWatchDataRange(
        startOfDay(secondWeek),
        endOfDay(addDays(firstWeek, -1)),
      ),
    ]);
    setFirstWeekEvents(firstWeekData);
    setSecondWeekEvents(secondWeekData);
  };

  const calculateDayWithMostEvents = (week: WatchData[], date: Date) => {
    if (!week) return;
    const days = eachDayOfInterval({
      start: addWeeks(date, -1),
      end: date,
    });
    const eventsByDay = days.map((day) => {
      const events = week.filter((event) =>
        isSameDay(parseISO(event.eventDate as any), day),
      );
      return {
        day,
        events,
      };
    });
    let day = undefined;
    for (let i = 0; i < eventsByDay.length; i++) {
      const dayOfWeek = eventsByDay[i];
      if (day && dayOfWeek.events.length > day.events.length) {
        day = dayOfWeek;
      } else if (!day && dayOfWeek.events.length) {
        day = dayOfWeek;
      }
    }
    return day;
  };

  const daysWithMostEventsData = () => {
    if (!firstWeekEvents || !secondWeekEvents) return;
    const first = calculateDayWithMostEvents(firstWeekEvents.data, new Date());
    const second = calculateDayWithMostEvents(
      secondWeekEvents.data,
      addWeeks(new Date(), -1),
    );
    setFirstWeekDay(first);
    setSecondWeekDay(second);
  };

  const calculateBPMFeedback = () => {
    if (!firstWeekEvents || firstWeekEvents.count === 0) return;
    const success = [];
    const doOver = [];
    const yes = [];
    for (let i = 0; i < firstWeekEvents.data.length; i++) {
      const event = firstWeekEvents.data[i];
      if (event.actionTaken === 'yes') {
        yes.push(event);
        if (event.actionRepeated) {
          doOver.push(event);
        } else {
          success.push(event);
        }
      }
    }
    const percentages = {
      success: (success.length / yes.length) * 100,
      doOver: (doOver.length / yes.length) * 100,
    };
    setDeviceBPMFeedback(percentages);
  };

  const calculateActivityResponses = () => {
    if (!firstWeekEvents) return;
    if (firstWeekEvents.count === 0) return;
    const yes = [];
    const no = [];
    const ignore = [];
    for (let i = 0; i < firstWeekEvents.data.length; i++) {
      const event = firstWeekEvents.data[i];
      if (event.actionTaken === 'yes') {
        yes.push(event);
      } else if (event.actionTaken === 'no') {
        no.push(event);
      } else if (event.actionTaken === 'ignore') {
        ignore.push(event);
      }
    }
    const percentages = {
      yes: (yes.length / firstWeekEvents.data.length) * 100,
      no: (no.length / firstWeekEvents.data.length) * 100,
      ignore: (ignore.length / firstWeekEvents.data.length) * 100,
    };
    setActivityResponses(percentages);
  };

  const bpmChangePostActivity = (week: WatchData[]) => {
    if (week.length === 0) return 0;
    const triggerHR = [];
    const postActionHR = [];
    for (let i = 0; i < week.length; i++) {
      const event = week[i];
      if (event.actionTaken === 'yes') {
        if (event.postActionHR) {
          postActionHR.push(event.postActionHR);
        }
        triggerHR.push(event.triggerHR);
      }
    }
    const triggerAverage =
      triggerHR.reduce((a, b) => a + b, 0) / triggerHR.length;
    const postActionAverage =
      postActionHR.reduce((a, b) => a + b, 0) / postActionHR.length;
    const percentageChange =
      ((triggerAverage - (triggerAverage - postActionAverage)) /
        triggerAverage) *
      100;
    return percentageChange;
  };

  const calculateBPMChange = () => {
    if (!firstWeekEvents || !secondWeekEvents) return;
    const first = bpmChangePostActivity(firstWeekEvents.data);
    const second = bpmChangePostActivity(secondWeekEvents.data);
    setFirstBPMChange(first);
    setSecondBPMChange(second);
  };

  return {
    fetchData,
    firstWeekDay,
    secondWeekDay,
    firstWeekEvents,
    secondWeekEvents,
    activityResponses,
    deviceBPMFeedback,
    firstBPMChange,
    secondBPMChange,
  };
};

export default useChartData;
