import { createSlice } from '@reduxjs/toolkit';
import { CallState, LoadingState } from '../types';
import { FrequentlyAskedQuestion } from '../types';

export interface FaqState {
  allFaqs: FrequentlyAskedQuestion[] | [];
  allFaqsLoading: CallState;
  createFaqLoading: CallState;
  updateFaqLoading: CallState;
  deleteFaqLoading: CallState;
}

export const faqSlice = createSlice({
  name: 'faq',
  initialState: {
    allFaqs: [],
    allFaqsLoading: LoadingState.INIT,
    createFaqLoading: LoadingState.INIT,
    updateFaqLoading: LoadingState.INIT,
    deleteFaqLoading: LoadingState.INIT,
  } as FaqState,
  reducers: {
    getAllFaqs: (state) => {
      state.allFaqsLoading = LoadingState.LOADING;
    },
    getAllFaqsSuccess: (state, { payload }) => {
      state.allFaqsLoading = LoadingState.LOADED;
      state.allFaqs = payload;
    },
    getAllFaqsFailure: (state, { payload }) => {
      state.allFaqsLoading = { error: payload };
    },
    createFaq: (state) => {
      state.createFaqLoading = LoadingState.LOADING;
    },
    createFaqSuccess: (state, { payload }) => {
      state.createFaqLoading = LoadingState.LOADED;
      state.allFaqs = payload;
    },
    createFaqFailure: (state, { payload }) => {
      state.createFaqLoading = { error: payload };
    },
    updateFaq: (state) => {
      state.updateFaqLoading = LoadingState.LOADING;
    },
    updateFaqSuccess: (state, { payload }) => {
      state.updateFaqLoading = LoadingState.LOADED;
      state.allFaqs = payload;
    },
    updateFaqFailure: (state, { payload }) => {
      state.updateFaqLoading = { error: payload };
    },
    deleteFaq: (state) => {
      state.deleteFaqLoading = LoadingState.LOADING;
    },
    deleteFaqSuccess: (state, { payload }) => {
      state.deleteFaqLoading = LoadingState.LOADED;
      state.allFaqs = payload;
    },
    deleteFaqFailure: (state, { payload }) => {
      state.deleteFaqLoading = { error: payload };
    },
  },
});

export const {
  getAllFaqs,
  getAllFaqsSuccess,
  getAllFaqsFailure,
  createFaq,
  createFaqSuccess,
  createFaqFailure,
  updateFaq,
  updateFaqSuccess,
  updateFaqFailure,
  deleteFaq,
  deleteFaqSuccess,
  deleteFaqFailure,
} = faqSlice.actions;

export default faqSlice.reducer;
