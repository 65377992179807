import useChartData from '../../../shared/hooks/useChartData';

const BPMChange = () => {
  const { firstBPMChange, secondBPMChange } = useChartData();

  const renderDifference = () => {
    if (!firstBPMChange || !secondBPMChange) return;
    if (firstBPMChange > secondBPMChange) {
      return (
        <span>
          {(firstBPMChange - secondBPMChange).toFixed()}% more than last week
        </span>
      );
    }
    if (firstBPMChange < secondBPMChange) {
      return (
        <span>
          {(secondBPMChange - firstBPMChange).toFixed()}% less than last week
        </span>
      );
    }
    if (firstBPMChange === secondBPMChange) {
      return <span>The same as last week</span>;
    }
  };
  return (
    <div>
      <span className="text-3xl font-bold">
        {firstBPMChange && Math.round(firstBPMChange)}%
      </span>
      <p className="text-gray-400">Positive BPM change post-activity</p>
      {firstBPMChange && secondBPMChange ? (
        <div
          className={`mt-5 flex items-center gap-x-2 font-bold ${
            firstBPMChange < secondBPMChange ? 'text-green-600' : 'text-red-500'
          }`}
        >
          {/* TODO: Make the icon conditional based on upward or downward trend */}
          <i
            className={`pi ${
              firstBPMChange < secondBPMChange ? 'pi-arrow-down' : 'pi-arrow-up'
            }`}
          />
          <p>
            {renderDifference()} ({secondBPMChange.toFixed()}%)
          </p>
        </div>
      ) : (
        <div className="mt-5 flex items-center gap-x-2 font-bold">
          No data to compare to last week.
        </div>
      )}
    </div>
  );
};

export default BPMChange;
