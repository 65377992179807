import React from 'react';
import { motion } from 'framer-motion';

const AnimatedLogoText: React.FC<{ color?: string }> = ({
  color = '#005F79',
}) => {
  const parentAnimationVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.1,
        type: 'tween',
      },
    },
  };

  const childAnimationVariants = {
    hidden: { opacity: 0, y: -50 },
    visible: { opacity: 1, y: 0 },
  };
  return (
    <motion.svg
      width="100%"
      viewBox="0 0 879 145"
      fill={color}
      xmlns="http://www.w3.org/2000/svg"
      variants={parentAnimationVariants}
      initial="hidden"
      animate="visible"
      className="overflow-visible"
    >
      <motion.path
        d="M70.3523 144.8C60.6323 144.8 51.5123 143 42.9923 139.4C34.5923 135.68 27.2123 130.58 20.8523 124.1C14.6123 117.5 9.69234 109.82 6.09234 101.06C2.61234 92.3 0.872345 82.82 0.872345 72.62C0.872345 62.54 2.61234 53.18 6.09234 44.54C9.69234 35.78 14.6123 28.16 20.8523 21.68C27.2123 15.08 34.5923 9.91999 42.9923 6.19999C51.3924 2.47999 60.5123 0.619995 70.3523 0.619995C79.5923 0.619995 87.5123 1.81999 94.1124 4.21999C100.832 6.61999 107.552 10.58 114.272 16.1C115.232 16.82 115.952 17.6 116.432 18.44C117.032 19.16 117.392 19.94 117.512 20.78C117.752 21.5 117.872 22.4 117.872 23.48C117.872 25.76 117.032 27.68 115.352 29.24C113.792 30.68 111.872 31.52 109.592 31.76C107.312 31.88 105.092 31.1 102.932 29.42C98.4924 25.58 93.8723 22.64 89.0723 20.6C84.3923 18.56 78.1523 17.54 70.3523 17.54C63.2723 17.54 56.6124 18.98 50.3723 21.86C44.1323 24.74 38.6123 28.7 33.8123 33.74C29.1323 38.78 25.4723 44.66 22.8323 51.38C20.1923 57.98 18.8723 65.06 18.8723 72.62C18.8723 80.3 20.1923 87.5 22.8323 94.22C25.4723 100.82 29.1323 106.64 33.8123 111.68C38.6123 116.72 44.1323 120.68 50.3723 123.56C56.6124 126.32 63.2723 127.7 70.3523 127.7C76.4723 127.7 82.2323 126.68 87.6323 124.64C93.1523 122.48 98.4324 119.54 103.472 115.82C105.632 114.26 107.732 113.6 109.772 113.84C111.932 113.96 113.732 114.8 115.172 116.36C116.612 117.8 117.332 119.84 117.332 122.48C117.332 123.68 117.092 124.88 116.612 126.08C116.132 127.16 115.412 128.18 114.452 129.14C107.972 134.3 101.012 138.2 93.5724 140.84C86.2524 143.48 78.5123 144.8 70.3523 144.8Z"
        variants={childAnimationVariants}
      />
      <motion.path
        d="M181.107 143.72C172.347 143.72 164.487 141.56 157.527 137.24C150.567 132.8 145.047 126.86 140.967 119.42C137.007 111.86 135.027 103.34 135.027 93.86C135.027 84.38 137.187 75.86 141.507 68.3C145.947 60.74 151.887 54.8 159.327 50.48C166.887 46.04 175.347 43.82 184.707 43.82C194.067 43.82 202.467 46.04 209.907 50.48C217.347 54.8 223.227 60.74 227.547 68.3C231.987 75.86 234.207 84.38 234.207 93.86H227.187C227.187 103.34 225.147 111.86 221.067 119.42C217.107 126.86 211.647 132.8 204.687 137.24C197.727 141.56 189.867 143.72 181.107 143.72ZM184.707 127.52C190.827 127.52 196.287 126.08 201.087 123.2C205.887 120.2 209.667 116.18 212.427 111.14C215.187 105.98 216.567 100.22 216.567 93.86C216.567 87.38 215.187 81.62 212.427 76.58C209.667 71.42 205.887 67.4 201.087 64.52C196.287 61.52 190.827 60.02 184.707 60.02C178.707 60.02 173.247 61.52 168.327 64.52C163.527 67.4 159.687 71.42 156.807 76.58C154.047 81.62 152.667 87.38 152.667 93.86C152.667 100.22 154.047 105.98 156.807 111.14C159.687 116.18 163.527 120.2 168.327 123.2C173.247 126.08 178.707 127.52 184.707 127.52ZM225.027 143.18C222.387 143.18 220.167 142.34 218.367 140.66C216.687 138.86 215.847 136.64 215.847 134V106.46L219.267 87.38L234.207 93.86V134C234.207 136.64 233.307 138.86 231.507 140.66C229.827 142.34 227.667 143.18 225.027 143.18Z"
        variants={childAnimationVariants}
      />
      <motion.path
        d="M286.732 143C281.452 143 276.772 141.56 272.692 138.68C268.612 135.8 265.432 131.9 263.152 126.98C260.872 121.94 259.732 116.18 259.732 109.7V11.42C259.732 8.77999 260.572 6.61999 262.252 4.93999C263.932 3.25999 266.092 2.41999 268.732 2.41999C271.372 2.41999 273.532 3.25999 275.212 4.93999C276.892 6.61999 277.732 8.77999 277.732 11.42V109.7C277.732 114.14 278.572 117.8 280.252 120.68C281.932 123.56 284.092 125 286.732 125H291.232C293.632 125 295.552 125.84 296.992 127.52C298.552 129.2 299.332 131.36 299.332 134C299.332 136.64 298.192 138.8 295.912 140.48C293.632 142.16 290.692 143 287.092 143H286.732Z"
        variants={childAnimationVariants}
      />
      <motion.path
        d="M358.288 143.72C348.568 143.72 339.988 141.62 332.548 137.42C325.108 133.1 319.228 127.22 314.908 119.78C310.708 112.22 308.608 103.58 308.608 93.86C308.608 84.02 310.708 75.38 314.908 67.94C319.228 60.38 325.108 54.5 332.548 50.3C339.988 45.98 348.568 43.82 358.288 43.82C367.888 43.82 376.408 45.98 383.848 50.3C391.288 54.5 397.108 60.38 401.308 67.94C405.628 75.38 407.788 84.02 407.788 93.86C407.788 103.58 405.688 112.22 401.488 119.78C397.288 127.22 391.468 133.1 384.028 137.42C376.588 141.62 368.008 143.72 358.288 143.72ZM358.288 127.52C364.528 127.52 370.048 126.08 374.848 123.2C379.648 120.32 383.368 116.36 386.008 111.32C388.768 106.28 390.148 100.46 390.148 93.86C390.148 87.26 388.768 81.44 386.008 76.4C383.368 71.24 379.648 67.22 374.848 64.34C370.048 61.46 364.528 60.02 358.288 60.02C352.048 60.02 346.528 61.46 341.728 64.34C336.928 67.22 333.148 71.24 330.388 76.4C327.628 81.44 326.248 87.26 326.248 93.86C326.248 100.46 327.628 106.28 330.388 111.32C333.148 116.36 336.928 120.32 341.728 123.2C346.528 126.08 352.048 127.52 358.288 127.52Z"
        variants={childAnimationVariants}
      />
      <motion.path
        d="M481.553 143.72C471.833 143.72 463.253 141.62 455.813 137.42C448.373 133.1 442.493 127.22 438.173 119.78C433.973 112.22 431.873 103.58 431.873 93.86C431.873 84.02 433.973 75.38 438.173 67.94C442.493 60.38 448.373 54.5 455.813 50.3C463.253 45.98 471.833 43.82 481.553 43.82C491.153 43.82 499.673 45.98 507.113 50.3C514.553 54.5 520.372 60.38 524.572 67.94C528.892 75.38 531.053 84.02 531.053 93.86C531.053 103.58 528.952 112.22 524.752 119.78C520.552 127.22 514.733 133.1 507.293 137.42C499.853 141.62 491.273 143.72 481.553 143.72ZM481.553 127.52C487.793 127.52 493.313 126.08 498.113 123.2C502.913 120.32 506.633 116.36 509.273 111.32C512.033 106.28 513.413 100.46 513.413 93.86C513.413 87.26 512.033 81.44 509.273 76.4C506.633 71.24 502.913 67.22 498.113 64.34C493.313 61.46 487.793 60.02 481.553 60.02C475.313 60.02 469.793 61.46 464.993 64.34C460.193 67.22 456.413 71.24 453.653 76.4C450.893 81.44 449.513 87.26 449.513 93.86C449.513 100.46 450.893 106.28 453.653 111.32C456.413 116.36 460.193 120.32 464.993 123.2C469.793 126.08 475.313 127.52 481.553 127.52Z"
        variants={childAnimationVariants}
      />
      <motion.path
        d="M595.997 143.72C587.597 143.72 579.797 142.46 572.597 139.94C565.517 137.3 559.997 134 556.037 130.04C554.237 128.12 553.457 125.96 553.697 123.56C554.057 121.04 555.257 119 557.297 117.44C559.697 115.52 562.037 114.74 564.317 115.1C566.717 115.34 568.757 116.36 570.437 118.16C572.477 120.44 575.717 122.6 580.157 124.64C584.717 126.56 589.757 127.52 595.277 127.52C602.237 127.52 607.517 126.38 611.117 124.1C614.837 121.82 616.757 118.88 616.877 115.28C616.997 111.68 615.257 108.56 611.657 105.92C608.177 103.28 601.757 101.12 592.397 99.44C580.277 97.04 571.457 93.44 565.937 88.64C560.537 83.84 557.837 77.96 557.837 71C557.837 64.88 559.637 59.84 563.237 55.88C566.837 51.8 571.457 48.8 577.097 46.88C582.737 44.84 588.617 43.82 594.737 43.82C602.657 43.82 609.677 45.08 615.797 47.6C621.917 50.12 626.777 53.6 630.377 58.04C632.057 59.96 632.837 62 632.717 64.16C632.597 66.2 631.577 67.94 629.657 69.38C627.737 70.7 625.457 71.12 622.817 70.64C620.177 70.16 617.957 69.08 616.157 67.4C613.157 64.52 609.917 62.54 606.437 61.46C602.957 60.38 598.937 59.84 594.377 59.84C589.097 59.84 584.597 60.74 580.877 62.54C577.277 64.34 575.477 66.98 575.477 70.46C575.477 72.62 576.017 74.6 577.097 76.4C578.297 78.08 580.577 79.64 583.937 81.08C587.297 82.4 592.217 83.72 598.697 85.04C607.697 86.84 614.777 89.12 619.937 91.88C625.217 94.64 628.997 97.88 631.277 101.6C633.557 105.2 634.697 109.4 634.697 114.2C634.697 119.72 633.197 124.7 630.197 129.14C627.317 133.58 622.997 137.12 617.237 139.76C611.597 142.4 604.517 143.72 595.997 143.72Z"
        variants={childAnimationVariants}
      />
      <motion.path
        d="M668.892 107.18C666.252 107.18 664.032 106.34 662.232 104.66C660.552 102.86 659.712 100.64 659.712 98V11.6C659.712 8.83999 660.552 6.61999 662.232 4.93999C664.032 3.25999 666.252 2.41999 668.892 2.41999C671.652 2.41999 673.872 3.25999 675.552 4.93999C677.232 6.61999 678.072 8.83999 678.072 11.6V98C678.072 100.64 677.232 102.86 675.552 104.66C673.872 106.34 671.652 107.18 668.892 107.18ZM742.872 143.18C740.232 143.18 738.012 142.34 736.212 140.66C734.532 138.86 733.692 136.64 733.692 134V89.72C733.692 82.88 732.432 77.3 729.912 72.98C727.392 68.66 723.972 65.48 719.652 63.44C715.452 61.28 710.592 60.2 705.072 60.2C700.032 60.2 695.472 61.22 691.392 63.26C687.312 65.3 684.072 68.06 681.672 71.54C679.272 74.9 678.072 78.8 678.072 83.24H666.732C666.732 75.68 668.532 68.96 672.132 63.08C675.852 57.08 680.892 52.34 687.252 48.86C693.612 45.38 700.752 43.64 708.672 43.64C716.952 43.64 724.332 45.44 730.812 49.04C737.412 52.52 742.572 57.68 746.292 64.52C750.132 71.36 752.052 79.76 752.052 89.72V134C752.052 136.64 751.152 138.86 749.352 140.66C747.672 142.34 745.512 143.18 742.872 143.18ZM668.892 143.18C666.252 143.18 664.032 142.34 662.232 140.66C660.552 138.86 659.712 136.64 659.712 134V53.72C659.712 50.96 660.552 48.74 662.232 47.06C664.032 45.38 666.252 44.54 668.892 44.54C671.652 44.54 673.872 45.38 675.552 47.06C677.232 48.74 678.072 50.96 678.072 53.72V134C678.072 136.64 677.232 138.86 675.552 140.66C673.872 142.34 671.652 143.18 668.892 143.18Z"
        variants={childAnimationVariants}
      />
      <motion.path
        d="M825.423 143.72C816.663 143.72 808.803 141.56 801.843 137.24C794.883 132.8 789.363 126.86 785.283 119.42C781.323 111.86 779.343 103.34 779.343 93.86C779.343 84.38 781.503 75.86 785.823 68.3C790.263 60.74 796.203 54.8 803.643 50.48C811.203 46.04 819.663 43.82 829.023 43.82C838.383 43.82 846.783 46.04 854.223 50.48C861.663 54.8 867.543 60.74 871.863 68.3C876.303 75.86 878.523 84.38 878.523 93.86H871.503C871.503 103.34 869.463 111.86 865.383 119.42C861.423 126.86 855.963 132.8 849.003 137.24C842.043 141.56 834.183 143.72 825.423 143.72ZM829.023 127.52C835.143 127.52 840.603 126.08 845.403 123.2C850.203 120.2 853.983 116.18 856.743 111.14C859.503 105.98 860.883 100.22 860.883 93.86C860.883 87.38 859.503 81.62 856.743 76.58C853.983 71.42 850.203 67.4 845.403 64.52C840.603 61.52 835.143 60.02 829.023 60.02C823.023 60.02 817.563 61.52 812.643 64.52C807.843 67.4 804.003 71.42 801.123 76.58C798.363 81.62 796.983 87.38 796.983 93.86C796.983 100.22 798.363 105.98 801.123 111.14C804.003 116.18 807.843 120.2 812.643 123.2C817.563 126.08 823.023 127.52 829.023 127.52ZM869.343 143.18C866.703 143.18 864.483 142.34 862.683 140.66C861.003 138.86 860.163 136.64 860.163 134V106.46L863.583 87.38L878.523 93.86V134C878.523 136.64 877.623 138.86 875.823 140.66C874.143 142.34 871.983 143.18 869.343 143.18Z"
        variants={childAnimationVariants}
      />
    </motion.svg>
  );
};

export default AnimatedLogoText;
